export const alignmentComparisonSortOptions = [
  {
    label: 'Alignment Score',
    value: 'score',
  },
  {
    label: 'A-Z',
    value: 'a-z',
  },
]

export const alignmentScoreFilterOptions = [
  {
    label: 'No Filter',
    value: 'none',
  },
  {
    label: 'Fully Aligned',
    value: 'fully-aligned',
  },
  {
    label: 'Not Aligned',
    value: 'not-aligned',
  },
  {
    label: 'Semi Aligned',
    value: 'semi-aligned',
  },
]

export const alignmentStatusFilterOptions = [
  {
    label: 'Alignment Required',
    value: 'alignment-required',
  },
  {
    label: 'Suggested Policies',
    value: 'suggested-policies',
  },
  {
    label: 'Aligned',
    value: 'aligned',
  },
  {
    label: 'Deviated',
    value: 'deviated',
  },
  {
    label: 'Accepted Deviation',
    value: 'accepted-deviation',
  },
]

export const alignmentPolicySortOptions = [
  {
    value: 'a-z',
    label: 'A-Z',
  },
  {
    value: 'policy-type',
    label: 'Policy Type',
  },
]
