/* eslint-disable camelcase */ /* eslint-disable react/jsx-pascal-case */
import Select from 'react-select'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { MRT_GlobalFilterTextField } from 'material-react-table'
import useSearchUserData from '../../api/useSearchUserData'
import ListTable from '../../components/ListTable/components/ListTable'
import TagCheck from '../../components/TenantTags/components/TagCheck'
import colourStyles from '../../config/ReactSelectColours'

const ToggleClientAdmin = () => {
  const [clients, setClients] = useState([])
  const [loadingState, setLoadingState] = useState(false)
  const [selectedClientId, setSelectedClientId] = useState('')
  const [requestError, setRequestError] = useState('')
  const [toggleError, setToggleUserError] = useState([])
  const [selectedUser, setSelectedUser] = useState()

  const navigate = useNavigate()

  const options = clients.map(client => ({
    value: client.clientId,
    label: client.displayName,
  }))

  useEffect(() => {
    const clientSearch = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/get-clients`
        )
        const { data } = response
        setClients(data)
      } catch (error) {
        console.error('Error:', error.response.status)
        toast.error('Error Retrieving Clients')
      }
    }
    clientSearch()
  }, [])

  const userSearchData = useSearchUserData()

  const handleClientChange = id => {
    userSearchData.mutate(id)
    setSelectedClientId(id)
  }

  const columns = useMemo(
    () => [
      {
        header: 'Username',
        accessor: 'username',
      },
      {
        header: 'Client Admin',
        accessor: 'clientAdmin',
        cell: ({ row }) => TagCheck(row.original.clientAdmin),
      },
    ],
    []
  )

  const toggleUser = async () => {
    try {
      setLoadingState(true)
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/toggleClientAdmin`,
        {
          changePwUserId: selectedUser[0].userid,
        }
      )

      // This is awful and should be refactored once the new .NET api is in place.
      // We are only doing it this way because we are migrating to .NET shortly (within the next 2 months) - LUKE

      if (response.data.Data.rowsAffected > 0) {
        toast.success('User Client Admin status toggled successfully')
        setToggleUserError([])
        userSearchData.mutate(selectedClientId)
      } else {
        setToggleUserError([
          'Could not toggle user admin status. Server Error.',
        ])
      }
    } catch (error) {
      if (error.response.status === 500) {
        setToggleUserError([
          'Could not toggle user admin status. Server Error.',
        ])
      }
    } finally {
      setLoadingState(false)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!selectedUser) {
      setRequestError('Please select a user')
      return
    }

    setRequestError('')

    toggleUser()
  }

  return (
    <div className='ui-panel admin-2-col'>
      <div className='heading'>
        <h2>Toggle Client Admin</h2>
      </div>

      <button
        type='button'
        onClick={() => navigate(-1)}
        className='btn bg-white text-gray-900 flex items-center justify-center mb-2'
      >
        <FontAwesomeIcon icon={icon({ name: 'angle-left' })} />
        <span>&nbsp;&nbsp;Back</span>
      </button>

      <div className='action-body no-progress-bar'>
        <form onSubmit={handleSubmit} className='w-1/2 flex flex-col gap-5'>
          {requestError && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <span className='font-small'>{requestError}</span>
            </div>
          )}

          {toggleError.length > 0 && (
            <div className='mt-4 mb-[24px]' role='alert'>
              <ul>
                {toggleError.map(issue => (
                  <li key={issue} className='font-small list-disc ml-4'>
                    {issue}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className='flex flex-col gap-2 w-1/3'>
            <label htmlFor='selectClient' className='font-semibold text-xl'>
              Select Client
            </label>
            <Select
              className='basic-single'
              classNamePrefix='select'
              value={
                options.find(option => option.value === selectedClientId) ||
                null
              }
              isSearchable
              menuPortalTarget={document.body}
              styles={{
                ...colourStyles,
              }}
              name='Clients'
              options={options}
              onChange={selectedOption => {
                handleClientChange(selectedOption.value)
              }}
              placeholder='Select a client'
            />
          </div>

          <div className='flex flex-col gap-2 mt-4'>
            <h3 className='font-semibold text-xl'>Select User</h3>
            <ListTable
              data={userSearchData.data || []}
              selectedListItems={selectedUser}
              setSelectedListItems={setSelectedUser}
              enableRowSelection
              loading={userSearchData.isPending}
              columns={columns}
              uniqueKey='username'
              columnOrder={['name', 'clientAdmin']}
              renderTopToolbar={({ table }) => (
                <div className='flex flex-row items-center gap-3 mb-3'>
                  <MRT_GlobalFilterTextField table={table} />
                </div>
              )}
            />
          </div>

          <button
            type='submit'
            disabled={loadingState}
            className='btn cyan-btn w-fit'
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default ToggleClientAdmin
