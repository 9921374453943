export const formatDateStr = str => {
  const [date] = str.split('T')
  const dateStr = date.replaceAll('-', '/')
  return dateStr
}

export const formatTimeStr = str => {
  const [, time] = str.split('T')
  const [hr, mins] = time.split(':')
  const timeStr = [hr, mins].join(':')
  return timeStr
}

export const formatDateTimeStr = str =>
  `${formatDateStr(str)} ${formatTimeStr(str)}`
