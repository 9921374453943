import {
  faCheckCircle,
  faCircleXmark,
  faInfoCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import Select from 'react-select'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import useOnboardingCodeValidator from '../hooks/UseOnboardingCodeValidator'
import Checkbox from './Checkbox'
import { validateEmail } from '../utils/Validation'
import Chip from './Chip/Chip'
import Tooltip from './tooltip/Tooltip'
import Alert from './Alert/Alert'
import findPartnerCustomer from './helpers'

const defaultNewCustomerState = {
  id: '',
  companyProfile: {
    domain: '',
    companyName: '',
    tenantId: '',
  },
  partnerCenter: false,
}

const statusFilterOptions = [
  {
    value: 'onboarded',
    label: 'Onboarded',
  },
  {
    value: 'partnerCenter',
    label: 'Partner Center Onboarding',
  },
  {
    value: 'credentials',
    label: 'Credentials Onboarding',
  },
]

const OnboardingSelectCustomers = ({ customers, onAddCustomer, onConfirm }) => {
  const [onboardInParallel, setOnboardInParallel] = useState(true)
  const [onboardingCode, setOnboardingCode] = useState('')
  const [hasValidated, setHasValidated] = useState(false)
  const [isValidID, setIsValidID] = useState(true)
  const [notificationEmail, setNotificationEmail] = useState('')
  const [statusFilters, setStatusFilters] = useState([])
  const [search, setSearch] = useState('')
  const [selectedCustomers, setSelectedCustomers] = useState([])

  const [addingCustomer, setAddingCustomer] = useState(false)
  const [newCustomer, setNewCustomer] = useState(defaultNewCustomerState)
  const [warning, setWarning] = useState(undefined)

  const codeValidation = useOnboardingCodeValidator()

  const isParallelOnboardingEnabled = useMemo(
    () =>
      !customers.some(
        customer =>
          (customer.partnerCenter === false ||
            !customer.allowDelegatedAccess) &&
          selectedCustomers.some(
            selectedCustomer => selectedCustomer.id === customer.id
          )
      ),
    [customers, selectedCustomers]
  )

  const isOnboardingEnabled = useMemo(() => {
    let allTrue = true
    let allFalse = true
    selectedCustomers.forEach(customer => {
      const { partnerCenter, allowDelegatedAccess } = customers.find(
        c => c.id === customer.id
      )
      if (partnerCenter && !allowDelegatedAccess) allTrue = false
      else allFalse = false
    })
    return allTrue || allFalse
  }, [customers, selectedCustomers])

  const handleCodeChange = e => {
    const newCode = e.target.value
    setOnboardingCode(newCode)

    if (!newCode) {
      setHasValidated(false)
      codeValidation.resetValidation()
    }
  }

  const handleValidateCode = e => {
    e.preventDefault()
    setHasValidated(true)
    codeValidation.validateCode(onboardingCode, response => {
      if (!response.allowedOnboardings < selectedCustomers.length)
        setSelectedCustomers([])
    })
  }

  const selectionEnabled =
    selectedCustomers.length < codeValidation.allowedOnboardings

  const validNewCustomer = useMemo(
    () =>
      newCustomer.id &&
      isValidID &&
      newCustomer.companyProfile.domain &&
      newCustomer.companyProfile.companyName &&
      selectionEnabled,
    [isValidID, selectionEnabled, newCustomer]
  )

  const filteredCustomers = useMemo(() => {
    const invalidCustomers = customers?.filter(
      customer =>
        !customer.companyProfile?.companyName ||
        !customer.companyProfile?.domain
    )
    const output = customers?.filter(customer => {
      if (customer.companyProfile.companyName === undefined) {
        return false
      }

      const onboardedSelected = statusFilters.some(
        status => status.value === 'onboarded'
      )
      const onboardedFilter = onboardedSelected && customer.onboardDisabled
      const partnerCenterSelected = statusFilters.some(
        status => status.value === 'partnerCenter'
      )
      const partnerCenterFilter =
        partnerCenterSelected &&
        customer.allowDelegatedAccess &&
        !customer.onboardDisabled
      const credentialsSelected = statusFilters.some(
        status => status.value === 'credentials'
      )
      const credentialsFilter =
        credentialsSelected &&
        !customer.allowDelegatedAccess &&
        !customer.onboardDisabled
      const none =
        !onboardedSelected && !partnerCenterSelected && !credentialsSelected
      const companyName = customer.companyProfile?.companyName.toLowerCase()
      const searchTerm = search.toLowerCase()
      const searchTermMatch = companyName.includes(searchTerm)
      return (
        searchTermMatch &&
        (none || onboardedFilter || partnerCenterFilter || credentialsFilter)
      )
    })
    output?.sort((a, b) => {
      if (a.allowDelegatedAccess !== b.allowDelegatedAccess) {
        return a.allowDelegatedAccess ? -1 : 1 // Sort by allowDelegatedAccess
      }
      if (a.onboardDisabled !== b.onboardDisabled) {
        return a.onboardDisabled ? 1 : -1 // Sort by onboardDisabled
      }
      return 0 // Objects are equal
    })
    if (invalidCustomers.length > 0) {
      setWarning({
        title: 'Invalid Customer Found',
        warningContent: (
          <div className='flex flex-col'>
            <p className='pb-4'>
              While retrieving customers we detected the following customers
              with either a missing Company name or Domain:
            </p>
            {invalidCustomers.map(customer => (
              <span className='pl-2' key={customer.id}>
                {`- ${customer.id}`}
              </span>
            ))}
          </div>
        ),
      })
    }
    return output
  }, [customers, search, statusFilters])

  const toggleCustomer = customer => {
    const foundCustomer = findPartnerCustomer(customers, customer)
    if (foundCustomer?.onboardDisabled) return

    setSelectedCustomers(prevSelectedCustomers =>
      findPartnerCustomer(prevSelectedCustomers, customer)
        ? prevSelectedCustomers.filter(
            c =>
              !(
                c.id === customer.id &&
                c.partnerCenter === customer.partnerCenter
              )
          )
        : [...prevSelectedCustomers, customer]
    )
  }

  const toggleSelectAll = () => {
    const selectableCustomers = filteredCustomers.filter(
      e => !e.onboardDisabled
    )

    const allSelected = selectedCustomers.length === selectableCustomers.length
    const someSelected = selectedCustomers.length > 0

    if (allSelected || someSelected) {
      setSelectedCustomers([])
    } else {
      setSelectedCustomers(
        selectableCustomers.slice(0, codeValidation.allowedOnboardings)
      )
    }
  }

  const updateNewCustomer = (
    customer = {
      id: undefined,
      companyProfile: {
        domain: undefined,
        companyName: undefined,
        tenantId: undefined,
      },
      partnerCenter: undefined,
    }
  ) => {
    setNewCustomer(oldCustomer => {
      const guidRegex =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
      const id = customer.id ?? oldCustomer.id

      if (guidRegex.test(id)) {
        setIsValidID(true)
      } else {
        setIsValidID(false)
      }

      const domain =
        customer.companyProfile?.domain ?? oldCustomer.companyProfile.domain
      const companyName =
        customer.companyProfile?.companyName ??
        oldCustomer.companyProfile.companyName

      const partnerCenter = customer.partnerCenter ?? oldCustomer.partnerCenter

      return {
        id,
        companyProfile: { domain, companyName, tenantId: id },
        partnerCenter,
      }
    })
  }

  const handleConfirmNewCustomer = e => {
    e.preventDefault()
    onAddCustomer({ ...newCustomer, partnerCenter: false })

    if (selectionEnabled) {
      toggleCustomer(newCustomer)
    }

    setNewCustomer(defaultNewCustomerState)
    setAddingCustomer(false)
    setSearch('')
  }

  const getChipProps = customer => {
    if (customer.onboardDisabled) {
      return {
        icon: <FontAwesomeIcon icon={faCheckCircle} />,
        color: 'black',
        text: 'Onboarded',
      }
    }
    if (customer.allowDelegatedAccess) {
      return {
        icon: (
          <Tooltip content='Automated onboarding is enabled'>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        ),
        color: 'blue',
        text: 'Partner Center Onboarding',
      }
    }
    return {
      icon: (
        <Tooltip content='Global admin credentials required to onboard'>
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      ),
      color: 'amber',
      text: 'Credential Onboarding',
    }
  }

  const buildCodeValidationIndicator = () => {
    if (!onboardingCode || !hasValidated) {
      return null
    }

    if (codeValidation.loading) {
      return (
        <div id='loading-spinner' className='ml-2'>
          <div className='loading' />
        </div>
      )
    }
    if (codeValidation.error) {
      return (
        <FontAwesomeIcon
          icon={faWarning}
          className='ml-2 validation-status-icon yellow'
        />
      )
    }
    if (!codeValidation.isValid) {
      return (
        <>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className='ml-2 mr-2 validation-status-icon red'
          />
          <span className='text-xs'> {codeValidation.message}</span>
        </>
      )
    }
    return (
      <>
        <FontAwesomeIcon
          icon={faCheckCircle}
          className='ml-2 mr-2 validation-status-icon cyan'
        />
        <span className='text-xs'>{codeValidation.message}</span>
      </>
    )
  }

  const buildCustomerList = () => (
    <>
      <div className='mb-4'>
        <p className='mb-4'>
          Please enter your onboarding code and confirm the customers you want
          to onboard.
        </p>
        <div className='mb-4 mr-6'>
          <label htmlFor='onboarding-code-input'>
            <b>Onboarding Code (Required)</b>
          </label>
          <div className='flex items-center'>
            <input
              id='onboarding-code-input'
              type='text'
              className='flex w-[300px] rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400'
              value={onboardingCode}
              onChange={handleCodeChange}
              onBlur={handleValidateCode}
            />
            <button
              id='validate-code-button'
              type='button'
              onClick={handleValidateCode}
              className='btn navy-btn find-btn ml-4'
            >
              Find
            </button>
            {buildCodeValidationIndicator()}
          </div>
        </div>
        <div className='mb-4'>
          <label>
            <b>Default Notification Email</b>
          </label>
          <input
            type='text'
            className='flex w-[300px] rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400'
            value={notificationEmail}
            onChange={v => setNotificationEmail(v.target.value)}
          />
        </div>
        <div className='mb-4'>
          <label htmlFor='select-status'>
            <b>Onboarding Status Filter</b>
          </label>
          <div className='mt-1 max-w-[600px] w-[100%]'>
            <Select
              classNames='flex bg-white text-gray-700 focus:outline-blue-400'
              value={statusFilters}
              options={statusFilterOptions}
              onChange={setStatusFilters}
              id='select-status'
              placeholder='Select Status...'
              isMulti
            />
          </div>
        </div>
      </div>
      <div className='flex flex-wrap items-center'>
        <input
          className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700  mr-4'
          type='text'
          placeholder='Search'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div>
          <button
            type='button'
            className='btn navy-btn mr-4 mt-4 mb-4'
            onClick={() => setSearch('')}
          >
            Clear
          </button>
          <button
            type='button'
            className='btn cyan-btn mr-4 mt-4 mb-4'
            onClick={toggleSelectAll}
            disabled={!codeValidation.isValid}
          >
            {selectedCustomers.length >= 1 ? 'Unselect All' : 'Select All'}
          </button>
          <div className='inline-block'>
            {selectedCustomers.length}/{codeValidation.allowedOnboardings}{' '}
            selected
          </div>
        </div>
      </div>
      <div className='text-xs mb-2 ml-2'>
        {filteredCustomers.length} customer
        {filteredCustomers.length === 1 ? '' : 's'} showing
      </div>
      <div className='table-container mb-6'>
        <table>
          <tbody>
            <tr className='table-head'>
              <td>Name</td>
              <td>Domain</td>
              <td colSpan={2}>Status</td>
            </tr>

            {filteredCustomers?.map(customer => {
              const { color, icon, text } = getChipProps(customer)
              const partnerCustomer = findPartnerCustomer(
                selectedCustomers,
                customer
              )
              return (
                <tr
                  key={`${customer.id}-${customer.partnerCenter}`}
                  onClick={
                    customer.onboardDisabled ||
                    (!selectionEnabled && !partnerCustomer)
                      ? null
                      : () => toggleCustomer(customer)
                  }
                  className={`tenant-row cursor-pointer${customer.onboardDisabled || (!selectionEnabled && !selectedCustomers.find(c => c.id === customer.id)) ? ' disabled' : ''}`}
                >
                  <td>{customer.companyProfile.companyName}</td>
                  <td>{customer.companyProfile.domain}</td>
                  <td>
                    <Chip color={color} icon={icon}>
                      {text}
                    </Chip>
                  </td>
                  <td className='check-cell'>
                    {partnerCustomer ? (
                      <FontAwesomeIcon icon={faCheckCircle} className='ml-2' />
                    ) : null}
                  </td>
                </tr>
              )
            })}
            {filteredCustomers?.length === 0 ? (
              <tr>
                <td colSpan={2}>No customers found</td>
                <td aria-label='Status Cell' />
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
      <div className='mb-4'>
        {!isOnboardingEnabled ? (
          <Alert title='Please note' type='warning' margin='my-6'>
            <p>
              Tenants must be onboarded either via Partner Center or using
              Credentials, it is not currently supported to mix the two methods.
            </p>
          </Alert>
        ) : null}
        {isParallelOnboardingEnabled && isOnboardingEnabled ? (
          <>
            <Checkbox
              disabled={isParallelOnboardingEnabled}
              checked={onboardInParallel}
              onChange={setOnboardInParallel}
              label={<b>Onboard all customers in parallel</b>}
            />
            <div className='mb-4 h-10'>
              {!validateEmail(notificationEmail) && onboardInParallel
                ? '"Default Notification Email" must be populated with a valid email address when onboarding all customers in parallel.'
                : ''}
            </div>
          </>
        ) : (
          <b className='block h-[44px]'>
            Partner Center onboarding is disabled when adding tenants using
            Credential Onboarding
          </b>
        )}
      </div>
      <button
        type='button'
        className='btn cyan-btn mr-4 mb-2'
        onClick={() =>
          onConfirm(
            selectedCustomers,
            onboardingCode,
            isParallelOnboardingEnabled && onboardInParallel,
            notificationEmail
          )
        }
        disabled={
          selectedCustomers.length === 0 ||
          !codeValidation.isValid ||
          (isOnboardingEnabled &&
            isParallelOnboardingEnabled &&
            onboardInParallel &&
            !validateEmail(notificationEmail))
        }
      >
        Confirm
      </button>
      <button
        type='button'
        className='btn tertiary-btn'
        disabled={!codeValidation.isValid}
        onClick={() => setAddingCustomer(codeValidation.isValid)}
      >
        Add Unlisted Customer
      </button>
    </>
  )

  const buildAddCustomerForm = () => (
    <>
      <p className='mb-4'>
        Please enter the details for the customer you wish to add.
      </p>
      <form onSubmit={handleConfirmNewCustomer}>
        <div className='mb-4'>
          <label htmlFor='tenantID'>
            <b>Tenant ID</b>
          </label>
          <input
            id='tenantID'
            name='tenantID'
            type='text'
            className={`flex rounded-md border p-2 ${isValidID ? 'border-gray-400' : 'border-red-500'} bg-white text-gray-700 focus:outline-blue-400 w-[380px]`}
            value={newCustomer.id}
            onChange={v => updateNewCustomer({ id: v.target.value.trim() })}
          />
          {!isValidID && <p className='text-red-500'>Invalid GUID format</p>}
        </div>
        <div className='mb-4'>
          <label htmlFor='companyName'>
            <b>Company Name</b>
          </label>
          <input
            id='companyName'
            name='companyName'
            type='text'
            className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400 w-[380px]'
            value={newCustomer.companyProfile.companyName}
            onChange={v =>
              updateNewCustomer({
                companyProfile: { companyName: v.target.value },
              })
            }
          />
        </div>
        <div className='mb-8'>
          <label htmlFor='domain'>
            <b>Domain (DNS Name)</b>
          </label>
          <input
            id='domain'
            name='domain'
            type='text'
            className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 focus:outline-blue-400 w-[380px]'
            value={newCustomer.companyProfile.domain}
            onChange={v =>
              updateNewCustomer({ companyProfile: { domain: v.target.value } })
            }
          />
        </div>
        <button
          className='btn cyan-btn mr-4 mb-2'
          disabled={!validNewCustomer}
          type='submit'
        >
          Add Customer
        </button>
        <button
          type='button'
          className='btn text-btn'
          onClick={() => setAddingCustomer(false)}
        >
          Back to Customer List
        </button>
      </form>
    </>
  )

  return (
    <>
      <h3 className='mb-4'>Step 2: Select customers to onboard</h3>
      {warning && (
        <Alert title={warning.title} type='warning'>
          {warning.warningContent}
        </Alert>
      )}
      {!addingCustomer ? buildCustomerList() : buildAddCustomerForm()}
    </>
  )
}

OnboardingSelectCustomers.propTypes = {
  customers: arrayOf(
    shape({
      id: string.isRequired,
      companyProfile: shape({
        domain: string.isRequired,
        companyName: string.isRequired,
        tenantId: string.isRequired,
      }).isRequired,
      partnerCenter: bool.isRequired,
      allowDelegatedAccess: bool.isRequired,
      onboardDisabled: bool.isRequired,
    })
  ).isRequired,
  onAddCustomer: func.isRequired,
  onConfirm: func.isRequired,
}

export default OnboardingSelectCustomers
