/* eslint-disable react/forbid-prop-types */
import { useState } from 'react'
import { arrayOf, bool, func, object } from 'prop-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import Axios from 'axios'
import Modal from '../Modal/Modal'
import { steps, summaryTenantShape } from './helpers'
import DeployConfigurationContent from './DeployPolicyConfiguration'
import DeployPolicyReviewSummary from './DeployPolicyReviewSummary'
import { createNewAdditionalSettingsConfig } from '../AdditionalOptions/AdditionalOptionsConfig'
import DeployPolicyFinalSummary from '../PolicyDeployment/DeployPolicyFinalSummary'
import createDeployPolicyPayload from '../PolicyDeployment/createDeployPolicyPayload'
import ConfirmationPopupSkeleton from '../LoadingSkeletons/ConfirmationPopupSkeleton'
import Alert from '../Alert/Alert'
import {
  pendingChangesStates,
  usePendingChangesStateContext,
} from '../../contexts/PendingChangesStateContext'

const DeployPolicyDialog = ({
  isOpen,
  setModalOpen,
  onCancel,
  selectedPolicies,
  sourceTenant,
  destinationTenants,
  additionalSettings,
  setAdditionalSettings,
}) => {
  const [step, setStep] = useState(steps.configuration)
  const queryClient = useQueryClient()
  const { setPendingChangesState, setPolicyIdsWithPendingChanges } =
    usePendingChangesStateContext()

  const deployPolicies = useMutation({
    mutationFn: deployPayload =>
      Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/send-deployment`,
        deployPayload
      ),
    onSuccess: () => {
      queryClient.invalidateQueries('tenant-summary')
      setPolicyIdsWithPendingChanges(existingPolicies => [
        ...existingPolicies,
        ...selectedPolicies.map(
          policy => policy.subjectPolicyId || policy.policyId
        ),
      ])
      setPendingChangesState(pendingChangesStates.actionInitiated)
      setStep(steps.final)
    },
  })

  if (selectedPolicies.length === 0)
    return (
      <Modal isOpen={isOpen} setModalOpen={setModalOpen}>
        No Policies Selected
        <button type='button' className='btn cyan-btn' onClick={onCancel}>
          Close
        </button>
      </Modal>
    )

  return (
    <Modal isOpen={isOpen} setModalOpen={setModalOpen} closeOnBlur={false}>
      {step === steps.configuration && (
        <DeployConfigurationContent
          selectedPolicies={selectedPolicies}
          additionalSettings={additionalSettings}
          setAdditionalSettings={setAdditionalSettings}
          onCancel={() => {
            setAdditionalSettings(createNewAdditionalSettingsConfig())
            onCancel()
          }}
          onConfirm={() => setStep(steps.review)}
        />
      )}
      {step === steps.review && !deployPolicies.isPending && (
        <DeployPolicyReviewSummary
          selectedPolicies={selectedPolicies.map(({ policyName, ...rest }) => ({
            displayName: policyName,
            ...rest,
          }))}
          additionalSettings={additionalSettings}
          sourceTenantName={sourceTenant.tenantFriendlyName}
          destinationTenants={destinationTenants}
          onSubmit={() => {
            deployPolicies.mutate(
              createDeployPolicyPayload(
                selectedPolicies,
                additionalSettings,
                sourceTenant,
                destinationTenants
              )
            )
          }}
          onPrevious={() => setStep(steps.configuration)}
        />
      )}
      {deployPolicies.isError && (
        <Alert
          title='Unexpected Error Occurred'
          type='error'
          message={deployPolicies.error}
        />
      )}
      {deployPolicies.isPending && (
        <ConfirmationPopupSkeleton loadingTitle='Deployment in progress...' />
      )}
      {step === steps.final && (
        <DeployPolicyFinalSummary
          successfulPolicies={deployPolicies.data.data.confirmationData}
          erroredPolicies={deployPolicies.data.data.errorMessages}
          additionalPolicySettings={additionalSettings.filter(setting =>
            selectedPolicies.some(
              policy => policy.policyTypeId === setting.policyId
            )
          )}
          onClose={() => {
            // reset additional settings object
            setAdditionalSettings(createNewAdditionalSettingsConfig())
            setModalOpen(false)
          }}
          sourceTenantName={sourceTenant.tenantFriendlyName}
          destinationTenants={destinationTenants}
        />
      )}
    </Modal>
  )
}

DeployPolicyDialog.propTypes = {
  isOpen: bool.isRequired,
  setModalOpen: func.isRequired,
  onCancel: func.isRequired,
  selectedPolicies: arrayOf(object).isRequired,
  additionalSettings: arrayOf(object).isRequired,
  setAdditionalSettings: func.isRequired,
  sourceTenant: summaryTenantShape.isRequired,
  destinationTenants: arrayOf(summaryTenantShape.isRequired).isRequired,
}

export default DeployPolicyDialog
