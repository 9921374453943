import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

const TagCheck = render =>
  render ? (
    <FontAwesomeIcon
      icon={faCheckCircle}
      aria-label='check'
      className='text-green-500'
    />
  ) : (
    <FontAwesomeIcon
      icon={faXmarkCircle}
      aria-label='xmark'
      className='text-gray-400'
    />
  )

export default TagCheck
