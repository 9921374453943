/* eslint-disable react/forbid-prop-types */
import { arrayOf, func, object } from 'prop-types'
import DeployPolicyDialogHeader from '../PolicyDeployment/DeployPolicyDialogHeader'
import AdditionalDeploymentOptions from '../AdditionalOptions/AdditionalDeploymentOptions'
import {
  updateAdditionalSettingValue,
  updateNestedAdditionalSetting,
} from '../AdditionalOptions/AdditionalOptionsConfig'
import ModalActionButtons from '../Modal/ModalActionButtons'
import { steps } from './helpers'

const DeployConfigurationContent = ({
  selectedPolicies,
  additionalSettings,
  setAdditionalSettings,
  onConfirm,
  onCancel,
}) => (
  <>
    <DeployPolicyDialogHeader step={steps.configuration} />
    <h3 className='text-center mt-6 mb-2'>Additional Settings</h3>
    <AdditionalDeploymentOptions
      additionalSettingsConfig={additionalSettings.filter(setting =>
        selectedPolicies.some(
          policy => policy.policyTypeId === setting.policyId
        )
      )}
      onChange={(policyId, flagName, newValue) => {
        setAdditionalSettings(prevState =>
          updateAdditionalSettingValue(prevState, policyId, flagName, newValue)
        )
      }}
      onNestedFlagChange={(
        policyId,
        parentFlagName,
        nestedFlagName,
        newValue
      ) => {
        setAdditionalSettings(prevState =>
          updateNestedAdditionalSetting(
            prevState,
            policyId,
            parentFlagName,
            nestedFlagName,
            newValue
          )
        )
      }}
    />
    <ModalActionButtons
      onCancel={onCancel}
      onConfirm={onConfirm}
      confirmActionText='Next'
    />
  </>
)

DeployConfigurationContent.propTypes = {
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
  selectedPolicies: arrayOf(object).isRequired,
  additionalSettings: arrayOf(object).isRequired,
  setAdditionalSettings: func.isRequired,
}

export default DeployConfigurationContent
