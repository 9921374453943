import { oneOf } from 'prop-types'
import { steps } from '../TenantAlignment/helpers'

const DeployPolicyDialogHeader = ({ step, alignment }) => (
  <h3 className={`${alignment === 'center' ? 'self-center' : ''} mb-4`}>
    {step === steps.final && 'Deployment Summary'}
    {step === steps.review && 'Review and confirm changes'}
    {step === steps.configuration && 'Align Policies'}
  </h3>
)

DeployPolicyDialogHeader.defaultProps = {
  alignment: 'center',
}

DeployPolicyDialogHeader.propTypes = {
  step: oneOf(['configuration', 'review', 'final']).isRequired,
  alignment: oneOf(['center', 'left']),
}

export default DeployPolicyDialogHeader
