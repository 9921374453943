/* eslint-disable react/forbid-prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { arrayOf, bool, func, object } from 'prop-types'
import {
  faLock,
  faMagnifyingGlass,
  faMinusCircle,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons'
import Axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { listTenantShape } from './TenantAlignment/helpers'

import Tooltip from './tooltip/Tooltip'
import IsReadOnlyPolicy from '../utils/IsReadOnlyPolicy'

const RestorePolicyPicker = ({
  data,
  checkedItems,
  showChildren,
  selectAll,
  handleParentCheckboxChange,
  handleChildCheckboxChange,
  handleSelectAll,
  handleDropdownToggle,
  selectedTargetTenant,
}) => {
  const hasChildren = children => Array.isArray(children) && children.length > 0
  if (!data || Object.keys(data).length === 0) {
    return <h4 className='my-[30px]'>No policies available</h4>
  }

  const handleViewClick = async (policy, parentName, childName) => {
    const fePolicy = {
      clientTenantId: +policy.clientTenantId,
      policyTypeId: +policy.policyTypeId,
      policyGuid: policy.policyGuid,
      policyId: +policy.policyId,
      target: 'SQL',
    }

    try {
      const response = await Axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/get-policy-data`,
        { params: fePolicy }
      )

      const policyData = Array.isArray(response.data)
        ? response.data[0]
        : response.data

      window.policyData = JSON.stringify(policyData)
      window.policyTypeName = JSON.stringify(parentName)
      window.policyDisplayName = JSON.stringify(childName)
      window.tenantName = JSON.stringify(
        selectedTargetTenant[0]?.tenantFriendlyName ?? ''
      )

      window.open(
        '/policy.html',
        `policy-viewer-${policyData.policyGuid}`,
        'width=600,height=900'
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div style={{ margin: '0 0 20px 0' }} />
      <div className='nested-checkboxes'>
        <div className='select-all-container'>
          <label>
            <input
              type='checkbox'
              checked={selectAll}
              onChange={handleSelectAll}
            />
            &nbsp;&nbsp;Select all
          </label>
        </div>
        {Object.entries(data).map(([parentType, children]) => (
          <div key={uuidv4()} className='parent-checkbox-container'>
            {hasChildren(children) && (
              <div className='parent-checkbox'>
                <div className='parent-check-label'>
                  <label className='round'>
                    <input
                      type='checkbox'
                      name={parentType}
                      checked={checkedItems[parentType] || false}
                      onChange={handleParentCheckboxChange}
                    />
                  </label>
                  &nbsp;&nbsp;{parentType}
                </div>
                <button
                  type='button'
                  aria-label='toggle'
                  onClick={() => handleDropdownToggle(parentType)}
                >
                  {showChildren[parentType] ? (
                    <FontAwesomeIcon icon={faMinusCircle} />
                  ) : (
                    <FontAwesomeIcon icon={faPlusCircle} />
                  )}
                </button>
              </div>
            )}
            {showChildren[parentType] && hasChildren(children) && (
              <ul className='checkbox-children'>
                {children.map(child => (
                  <li key={uuidv4()}>
                    {IsReadOnlyPolicy(child) ? (
                      <span className='lock' aria-label='Padlock'>
                        <Tooltip content='Read Only'>
                          <FontAwesomeIcon icon={faLock} />
                        </Tooltip>
                      </span>
                    ) : (
                      <input
                        aria-label='Child policy selected toggle'
                        id={`selectedChildPolicy-${child.id}`}
                        type='checkbox'
                        name={child.policyGuid}
                        checked={checkedItems[child.policyGuid] || false}
                        onChange={handleChildCheckboxChange}
                      />
                    )}

                    <span
                      className={`${IsReadOnlyPolicy(child) ? 'opacity-70 pr-3' : ''} policy-name`}
                    >
                      {child.PolicyName || child.name}
                    </span>

                    <div className='policy-explorer-btns flex items-center'>
                      <div className='flex items-center'>
                        <button
                          type='button'
                          aria-label='View'
                          className='btn'
                          onClick={() =>
                            handleViewClick(child, parentType, child.PolicyName)
                          }
                        >
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
            {!hasChildren(children) && <div>No children found.</div>}
          </div>
        ))}
      </div>
    </div>
  )
}

RestorePolicyPicker.propTypes = {
  data: object.isRequired,
  checkedItems: object.isRequired,
  showChildren: object.isRequired,
  selectAll: bool.isRequired,
  handleParentCheckboxChange: func.isRequired,
  handleChildCheckboxChange: func.isRequired,
  handleSelectAll: func.isRequired,
  handleDropdownToggle: func.isRequired,
  selectedTargetTenant: arrayOf(listTenantShape),
}

RestorePolicyPicker.defaultProps = {
  selectedTargetTenant: [],
}

export default RestorePolicyPicker
