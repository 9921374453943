import { createContext, useReducer, useContext, useMemo } from 'react'
import { node } from 'prop-types'

const ExpansionContext = createContext()

const expansionInitialState = {
  expandedItems: {},
}

const expansionReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_EXPAND':
      return {
        ...state,
        expandedItems: {
          ...state.expandedItems,
          [action.payload]: !state.expandedItems[action.payload],
        },
      }

    case 'TOGGLE_ALL_EXPAND': {
      // Determine if we should expand all or collapse all within this specific group
      const shouldExpandAll = action.payload.some(
        item => !state.expandedItems[item]
      )

      // Expand or collapse all items within the group, and ensure the parent group is also expanded
      const expandedItems = action.payload.reduce((acc, item) => {
        acc[item] = shouldExpandAll
        return acc
      }, {})

      // Additionally, ensure the parent group is expanded if any of its children are expanded
      if (shouldExpandAll && action.parentGroup) {
        expandedItems[action.parentGroup] = true
      }

      return {
        ...state,
        expandedItems: {
          ...state.expandedItems,
          ...expandedItems,
        },
      }
    }

    case 'RESET':
      return expansionInitialState

    default:
      return state
  }
}

export const ExpansionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(expansionReducer, expansionInitialState)

  const contextValue = useMemo(() => {
    const toggleExpand = item => {
      dispatch({ type: 'TOGGLE_EXPAND', payload: item })
    }

    const toggleExpandAll = (policyData, parentGroup) => {
      dispatch({
        type: 'TOGGLE_ALL_EXPAND',
        payload: policyData,
        parentGroup,
      })
    }

    const isExpanded = item => !!state.expandedItems[item]

    const isAllExpanded = items =>
      items.every(item => state.expandedItems[item])

    const resetExpansionState = () => {
      dispatch({
        type: 'RESET',
      })
    }

    return {
      state,
      dispatch,
      toggleExpand,
      toggleExpandAll,
      isExpanded,
      isAllExpanded,
      resetExpansionState,
    }
  }, [state, dispatch])

  return (
    <ExpansionContext.Provider value={contextValue}>
      {children}
    </ExpansionContext.Provider>
  )
}

export const useExpansion = () => {
  const context = useContext(ExpansionContext)
  if (!context) {
    throw new Error('useExpansion must be used within a ExpansionProvider')
  }
  return context
}

ExpansionProvider.propTypes = {
  children: node.isRequired,
}
