import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { useFeatureFlag } from 'configcat-react'

import { useMemo, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'

import {
  connectionStatusFilter,
  tableColumnDefinitions,
  tableColumnOrder,
} from './tableProps'
import useGetDashboardQuery from './useGetDashboardQuery'
import Modal from '../Modal/Modal'
import CustomTableToolbar from './CustomTableToolbar'
import useTenantTags from '../TenantTags/api/useTenantTags'
import getUniqueTagsFromTenants from '../../utils/uniqueTenantTags'

const HomeDashboard = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [hideBaselines, setHideBaselines] = useState(true)

  const { data, isLoading } = useGetDashboardQuery()

  const { value: enableTenantTags } = useFeatureFlag('enableTenantTags', false)

  const { data: allTenantTags, isLoading: isTenantTagsLoading } =
    useTenantTags(enableTenantTags)

  const uniqueTenantTags = getUniqueTagsFromTenants({
    tenants: data?.tenants,
    tenantTags: allTenantTags,
  })

  const columns = useMemo(
    () =>
      tableColumnDefinitions({
        setModalContent: newModalContent => {
          setModalOpen(true)
          setModalContent(newModalContent)
        },
        allTenantTags,
        uniqueTenantTags,
        enableTenantTags,
      }),
    [uniqueTenantTags, allTenantTags, enableTenantTags]
  )

  const tenantsData = useMemo(
    () =>
      data?.tenants
        ? data.tenants.filter(tenants =>
            hideBaselines ? !tenants.isBaseline : true
          )
        : [],
    [data, hideBaselines]
  )

  const tableInstance = useMaterialReactTable({
    columns,
    data: tenantsData,
    state: {
      isLoading: isLoading || isTenantTagsLoading,
    },
    columnFilterDisplayMode: 'popover',
    enableStickyHeader: true,
    enableColumnOrdering: true,
    renderTopToolbarCustomActions: ({ table }) => (
      <CustomTableToolbar
        exportCsvDisabled={table.getPrePaginationRowModel().rows.length === 0}
        rowData={table.getPrePaginationRowModel().rows.map(row => row.original)}
        hideBaselines={hideBaselines}
        setHideBaselines={setHideBaselines}
      />
    ),
    enableColumnPinning: true,
    enableColumnActions: false,
    enableFacetedValues: true,
    filterFns: {
      connectionStatus: connectionStatusFilter,
    },
    initialState: {
      density: 'compact',
      columnPinning: { left: ['tenantFriendlyName'] },
      columnOrder: tableColumnOrder,
      showGlobalFilter: true,
      sorting: [{ id: 'alignmentScore', asc: true }],
      columnVisibility: { isBaseline: !hideBaselines, connectionStatus: false },
    },
    muiTableContainerProps: {
      sx: { maxHeight: '100%' },
    },
    muiTableHeadCellProps: {
      sx: {
        fontFamily: 'inherit',
        color: 'rgba(23, 27, 58, 0.85)',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontFamily: 'inherit',
        color: 'rgba(23, 27, 58, 0.85)',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTableFooterProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTopToolbarProps: {
      sx: {
        '& .MuiBox-root': {
          padding: 0,
        },
      },
    },
  })

  return (
    <>
      <Modal isOpen={isModalOpen} setModalOpen={setModalOpen}>
        {modalContent || ''}
      </Modal>
      <div className='p-4 bg-white'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={tableInstance} />
        </LocalizationProvider>
      </div>
    </>
  )
}

export default HomeDashboard
