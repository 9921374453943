import {
  customQuarantinePolicies,
  mailFlowRuleMode,
} from './AdditionalOptionsConfig'

export const restoreOriginalAssignments = {
  text: 'Restore original assignments for all policies?',
  flagName: 'restoreAssignments',
  value: false,
}

export const restoreNonStandardApplications = {
  text: 'Restore non-standard applications?',
  flagName: 'restoreNonStandardApplications',
  value: false,
}

export const restorePolicyDisabled = {
  text: 'Force policies to restore in disabled state?',
  flagName: 'restorePolicyDisabled',
  value: true,
}

export const restoreCompliancePolicyActions = {
  text: 'Restore compliance policy actions?',
  flagName: 'restoreCompliancePolicyActions',
  value: false,
}

export const overwriteExistingPolicy = {
  text: 'Overwrite policy being restored',
  flagName: 'overwriteExistingPolicy',
  value: false,
}

export const restoreAllDomains = {
  text: 'Restore to all domains?',
  flagName: 'restoreAllDomains',
  value: false,
}

export const restoreNonStandardApplicationsConditionalAccess = {
  text: 'Restore non-standard applications?',
  flagName: 'restoreNonStandardApplications',
  value: true,
}

export const restoreLocationInformationConditionalAccess = {
  text: 'Restore original location information?',
  flagName: 'restoreLocations',
  value: true,
}

export const restorePriorityHigh = {
  text: 'Restore with high priority',
  flagName: 'restorePriorityHigh',
  value: true,
}

const commonSettings = [overwriteExistingPolicy, restoreOriginalAssignments]

export const additionalSettingsConfig = [
  {
    policyId: 1,
    policyTypeId: 1,
    policyHeading: 'Conditional Access Policies',
    additionalSettings: [
      restoreOriginalAssignments,
      restoreNonStandardApplicationsConditionalAccess,
      restorePolicyDisabled,
      restoreLocationInformationConditionalAccess,
      restoreCompliancePolicyActions,
      overwriteExistingPolicy,
    ],
  },
  {
    policyId: 2,
    policyTypeId: 2,
    policyHeading: 'Conditional Access Named Location',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 3,
    policyTypeId: 3,
    policyHeading: 'Device Compliance Policies',
    additionalSettings: [...commonSettings],
  },
  {
    policyId: 4,
    policyTypeId: 4,
    policyHeading: 'Device Configurations',
    additionalSettings: [...commonSettings],
  },
  {
    policyId: 5,
    policyTypeId: 5,
    policyHeading: 'Group Policy Configurations',
    additionalSettings: [...commonSettings],
  },
  {
    policyId: 6,
    policyTypeId: 6,
    policyHeading: 'Managed App Policies',
    additionalSettings: [...commonSettings],
  },
  {
    policyId: 9,
    policyTypeId: 9,
    policyHeading: 'Windows Autopilot Deployment Profiles',
    additionalSettings: [...commonSettings],
  },
  {
    policyId: 10,
    policyTypeId: 10,
    policyHeading: 'Configuration Policies',
    additionalSettings: [...commonSettings],
  },
  {
    policyId: 11,
    policyTypeId: 11,
    policyHeading: 'Intents',
    additionalSettings: [...commonSettings],
  },

  /*
   * Types 15 - 25 we add conditional high priority option
   */

  {
    policyId: 15,
    policyTypeId: 15,
    policyHeading: 'Exchange Org-Level Settings',
    additionalSettings: [
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      restoreAllDomains,
    ],
  },

  {
    policyId: 19,
    policyTypeId: 19,
    policyHeading: 'Anti-Phishing (Defender 365) Settings',
    additionalSettings: [
      restorePolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      customQuarantinePolicies,
      restoreAllDomains,
    ],
  },
  {
    policyId: 20,
    policyTypeId: 20,
    policyHeading: 'Anti-Malware (Defender 365) Settings',
    additionalSettings: [
      restorePolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      customQuarantinePolicies,
      restoreAllDomains,
    ],
  },
  {
    policyId: 21,
    policyTypeId: 21,
    policyHeading: 'Safe Attachments (Defender 365) Settings',
    additionalSettings: [
      restorePolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      customQuarantinePolicies,
      restoreAllDomains,
    ],
  },
  {
    policyId: 22,
    policyTypeId: 22,
    policyHeading: 'Safe Links (Defender 365) Settings',
    additionalSettings: [
      restorePolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      restoreAllDomains,
    ],
  },
  {
    policyId: 23,
    policyTypeId: 23,
    policyHeading: 'Spam Policy (Inbound) (Defender 365) Settings',
    additionalSettings: [
      restorePolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      customQuarantinePolicies,
      restoreAllDomains,
    ],
  },
  {
    policyId: 24,
    policyTypeId: 24,
    policyHeading: 'Spam Policy (Outbound) (Defender 365) Settings',
    additionalSettings: [
      restorePolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      restoreAllDomains,
    ],
  },
  {
    policyId: 25,
    policyTypeId: 25,
    policyHeading: 'Mail Flow Rules',
    additionalSettings: [
      restorePolicyDisabled,
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      mailFlowRuleMode,
    ],
  },
  {
    policyId: 28,
    policyTypeId: 28,
    policyHeading: 'Feature Update Profiles',
    additionalSettings: [
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      restoreAllDomains,
    ],
  },
  {
    policyId: 29,
    policyTypeId: 29,
    policyHeading: 'Quality Update Profiles',
    additionalSettings: [
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      restoreAllDomains,
    ],
  },
  {
    policyId: 30,
    policyTypeId: 30,
    policyHeading: 'Driver Update Profiles',
    additionalSettings: [
      {
        ...overwriteExistingPolicy,
        renderExchangeOptions: [restorePriorityHigh],
      },
      restoreAllDomains,
    ],
  },
  {
    policyId: 31,
    policyTypeId: 31,
    policyHeading: 'Assignment filters',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 33,
    policyTypeId: 33,
    policyHeading: 'Quarantine Policy (Defender 365)',
    additionalSettings: [overwriteExistingPolicy],
  },
  {
    policyId: 34,
    policyTypeId: 34,
    policyHeading: 'Intune Scripts (Windows)',
    additionalSettings: [...commonSettings],
  },
  {
    policyId: 35,
    policyTypeId: 35,
    policyHeading: 'Intune Scripts (macOS)',
    additionalSettings: [...commonSettings],
  },
]
