import { bool } from 'prop-types'

/**
 * ExpandButton component
 * @param {boolean} isExpanded - boolean to check if expanded
 * @param {function} setIsExpanded - function to set expand
 * @returns {JSX.Element}
 */
const ExpandButton = ({ isExpanded }) => (
  <button
    type='button'
    className='bg-black text-white text-sm rounded-full h-6 w-6 flex items-center justify-center'
  >
    {isExpanded ? '-' : '+'}
  </button>
)

export default ExpandButton

ExpandButton.propTypes = {
  isExpanded: bool.isRequired,
}
