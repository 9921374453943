import { useMemo } from 'react'

const useGroupedOptions = (groupedPolicies, isLoading) =>
  useMemo(() => {
    if (isLoading) return []

    const products = new Set()
    const operatingSystems = new Set()

    groupedPolicies.forEach(group => {
      products.add(group.name)
      group.subGroups.forEach(subGroup => {
        operatingSystems.add(subGroup.name)
      })
    })

    return [
      {
        label: 'Product',
        options: Array.from(products).map(product => ({
          value: product,
          label: product,
          group: 'Product',
        })),
      },
      {
        label: 'Operating System',
        options: Array.from(operatingSystems).map(os => ({
          value: os,
          label: os,
          group: 'Operating System',
        })),
      },
    ]
  }, [groupedPolicies, isLoading])

export default useGroupedOptions
