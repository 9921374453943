import { useFeatureFlag } from 'configcat-react'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { v4 as uuidv4 } from 'uuid'
import Axios from 'axios'
import RestorePolicyPicker from '../../components/RestorePolicyPicker'
import RestoreSummary from '../../components/PolicyDeployment/RestoreSummary'

import ReviewSummaryContent from '../../components/RestoreReviewSummary'
import { additionalSettingsConfig } from '../../components/AdditionalOptions/AdditionalOptionsRestoreConfig'

import {
  getFlagsForPolicy,
  updateAdditionalSettingValue,
  updateNestedAdditionalSetting,
} from '../../components/AdditionalOptions/AdditionalOptionsConfig'
import AdditionalDeploymentOptions from '../../components/AdditionalOptions/AdditionalDeploymentOptions'
import RestorePopup from '../../components/RestorePopup'
import PolicyPickerSkeleton from '../../components/LoadingSkeletons/PolicyPickerSkeleton'

import TenantSkeleton from '../../components/LoadingSkeletons/TenantSkeleton'
import DatePicker from '../../components/DatePicker'

import BackUpCommentPicker from '../../components/BackUpCommentPicker'
import IsReadOnlyPolicy from '../../utils/IsReadOnlyPolicy'

import { useNotificationsContext } from '../../contexts/NotificationsContext'
import useGetListTenants from '../../hooks/useGetListTenants'
import useTenantTags from '../../components/TenantTags/api/useTenantTags'
import uniqueTenantTags from '../../utils/uniqueTenantTags'
import tenantTypeFilter from '../../components/TableCells/TenantTypeCell/filters'
import TenantTypeCell from '../../components/TableCells/TenantTypeCell/TenantTypeCell'
import TenantTagsCell from '../../components/TableCells/TenantTagsCell/TenantTagsCell'
import ListTable from '../../components/ListTable/components/ListTable'
import Alert from '../../components/Alert/Alert'

const Restore = () => {
  const {
    data: legacyTenants,
    isLoading: isLegacyTenantsLoading,
    isError: isLegacyTenantsError,
    error: legacyTenantsError,
  } = useGetListTenants()

  const { value: enableTenantTags } = useFeatureFlag('enableTenantTags', false)

  const { data: tenantTags, isLoading: isTenantTagsLoading } =
    useTenantTags(enableTenantTags)

  const [selectedSourceTenant, setSelectedSourceTenant] = useState([])

  const uniqueTags = uniqueTenantTags({
    tenants: legacyTenants,
    tenantTags,
  })

  const tenantsColumns = useMemo(
    () => [
      {
        header: 'Tenant Name',
        accessor: 'tenantFriendlyName',
      },
      {
        header: 'Type',
        accessor: 'type',
        size: 200,
        filterVariant: 'multi-select',
        filterSelectOptions: [
          'Baseline',
          'Shared Baseline',
          'Inforcer Managed',
          'Customer',
        ],
        filterFn: tenantTypeFilter,
        cell: ({ row }) => TenantTypeCell({ row }),
      },
      {
        header: 'Tags',
        accessor: 'tags',
        cell: ({ row }) =>
          TenantTagsCell({
            linkedTenantTagIds: row.original.tags,
            allTenantTags: tenantTags,
            numVisibleTags: 4,
          }),
        size: 700,
        minSize: 100,
        maxSize: 800,
        grow: true,
        filterVariant: 'multi-select',
        filterSelectOptions: uniqueTags.map(tag => ({
          value: tag.id,
          label: tag.tag,
        })),
      },
    ],
    [tenantTags, uniqueTags]
  )

  // Form Logic
  const formArray = [1, 2, 3, 4, 5]
  const [formNo, setFormNo] = useState(formArray[0])

  const { refetchNotifications } = useNotificationsContext()

  const [loadingState, setLoadingState] = useState(true)

  // Backup dates
  const [dates, setDates] = useState('')
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedComment, setSelectedComment] = useState({ value: '' })

  // Restore selector
  const [selectedOption, setSelectedOption] = useState('date')
  const [comment, setComment] = useState('')

  // Policy picker
  const [checkedItems, setCheckedItems] = useState({})
  const [selectedObjects, setSelectedObjects] = useState([])
  const [data, setData] = useState([])
  const [showChildren, setShowChildren] = useState({})
  const [selectAll, setSelectAll] = useState(false)
  const [openDropdowns, setOpenDropdowns] = useState([])
  const [abortController, setAbortController] = useState(null)

  // Additional options
  const [selectedPolicies, setSelectedPolicies] = useState([])
  const [additionalSettings, setAdditionalSettings] = useState(
    JSON.parse(JSON.stringify(additionalSettingsConfig))
  )

  // Parse data for posting
  const [formResponses, setFormResponses] = useState([])

  // Final response
  const [finalResponse, setFinalResponse] = useState(null)
  const [backEndErrors, setBackEndErrors] = useState(null)
  const [openPopup, setOpenPopup] = useState(false)

  // Reset the selectedDate and selectedComment states when the selectedOption changes
  useEffect(() => {
    setSelectedDate(null)
    setSelectedComment({ value: '' })
  }, [selectedOption])

  useEffect(() => {
    if (formNo === 1) {
      setFormResponses([])
    }
  }, [formNo])

  const checkParentCheckbox = (parentType, updatedCheckedItems) => {
    const allChildrenChecked = data[parentType]
      .filter(childPolicy => !IsReadOnlyPolicy(childPolicy))
      .map(child => updatedCheckedItems[child.policyGuid.toString()])
    // TODO - ESLINT FIX ME
    // eslint-disable-next-line no-param-reassign
    updatedCheckedItems[parentType] = allChildrenChecked.every(Boolean)

    setCheckedItems(updatedCheckedItems)
  }

  // Policy picker logic
  const handleParentCheckboxChange = e => {
    const { name, checked } = e.target
    const updatedCheckedItems = { ...checkedItems }
    const updatedShowChildren = { ...showChildren }

    updatedCheckedItems[name] = checked
    updatedShowChildren[name] = checked

    if (checked) {
      data[name].forEach(child => {
        if (!IsReadOnlyPolicy(child)) {
          updatedCheckedItems[child.policyGuid] = true

          if (
            !selectedObjects.some(obj => obj.policyGuid === child.policyGuid)
          ) {
            setSelectedObjects(prevSelectedObjects => [
              ...prevSelectedObjects,
              child,
            ])
          }
        }
      })
    } else {
      data[name].forEach(child => {
        updatedCheckedItems[child.policyGuid] = false
        setSelectedObjects(prevSelectedObjects =>
          prevSelectedObjects.filter(obj => obj.policyGuid !== child.policyGuid)
        )
      })
    }

    setCheckedItems(updatedCheckedItems)
    setShowChildren(updatedShowChildren)

    checkParentCheckbox(name, updatedCheckedItems)

    const containsFalse = Object.values(updatedCheckedItems).includes(false)

    if (containsFalse === true) {
      setSelectAll(false)
    }
  }

  const handleChildCheckboxChange = e => {
    const { name, checked } = e.target
    const updatedCheckedItems = { ...checkedItems }

    updatedCheckedItems[name] = checked

    const parentType = Object.keys(data).find(type =>
      data[type].some(obj => obj.policyGuid.toString() === name)
    )

    if (checked) {
      // const selectedObject = data[parentType].find((obj) => obj.policyGuid === parseInt(name))
      const selectedObject = data[parentType].find(
        obj => obj.policyGuid.toString() === name
      )
      setSelectedObjects(prevSelectedObjects => [
        ...prevSelectedObjects,
        selectedObject,
      ])
    } else {
      setSelectedObjects(prevSelectedObjects =>
        prevSelectedObjects.filter(obj => obj.policyGuid !== name)
      )
      // If at least one child is deselected, set the parent checkbox to false
      updatedCheckedItems[parentType] = false
    }

    checkParentCheckbox(parentType, updatedCheckedItems)

    const containsFalse = Object.values(updatedCheckedItems).includes(false)

    if (containsFalse === true) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }

    setCheckedItems(updatedCheckedItems)
  }

  const handleSelectAll = () => {
    const updatedCheckedItems = {}
    const updatedSelectedObjects = []

    if (!selectAll) {
      Object.entries(data).forEach(([parentType, children]) => {
        updatedCheckedItems[parentType] = true
        children.forEach(child => {
          if (!IsReadOnlyPolicy(child)) {
            updatedCheckedItems[child.policyGuid] = true

            if (
              !updatedSelectedObjects.some(
                obj => obj.policyGuid === child.policyGuid
              )
            ) {
              updatedSelectedObjects.push(child)
            }
          }
        })
      })

      setShowChildren({}) // Hide all dropdowns when select all is checked
    } else {
      // Uncheck all checkboxes when select all is unchecked
      Object.keys(updatedCheckedItems).forEach(key => {
        updatedCheckedItems[key] = false
      })
    }

    setCheckedItems(updatedCheckedItems)
    setSelectedObjects(updatedSelectedObjects)
    setSelectAll(!selectAll)
  }

  const handleDropdownToggle = parentType => {
    if (parentType === 'selectAll') {
      return // Ignore dropdown toggle triggered by "Select All" checkbox
    }

    const updatedShowChildren = { ...showChildren }
    updatedShowChildren[parentType] = !showChildren[parentType]

    setShowChildren(updatedShowChildren)

    if (updatedShowChildren[parentType]) {
      setOpenDropdowns([...openDropdowns, parentType])
    } else {
      setOpenDropdowns(openDropdowns.filter(type => type !== parentType))
    }
  }

  // Timeline navigation
  const next = () => {
    if (formNo === 1) {
      if (selectedSourceTenant.length === 0) {
        return
      }

      const stepOneRes = { step: formNo, response: selectedSourceTenant }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepOneRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepOneRes,
        ])
      }

      setData([])
      // Get clientTenantId so it can be passed
      const ctId = stepOneRes.response[0].clientTenantId

      const getBackUpDates = async () => {
        setLoadingState(true)

        try {
          const response = await Axios.post(
            `${process.env.REACT_APP_MIDDLEWARE_URL}/get-backups-by-date`,
            {
              ctId,
            }
          )

          setDates(response.data)
          setLoadingState(false)
        } catch (error) {
          toast.error('Error Retrieving Backups')
        } finally {
          setLoadingState(false)
        }
      }

      getBackUpDates()

      const getBackUpComments = async () => {
        setLoadingState(true)

        try {
          const response = await Axios.post(
            `${process.env.REACT_APP_MIDDLEWARE_URL}/get-backups-by-comment`,
            {
              ctId,
            }
          )

          setComment(response.data)
          setLoadingState(false)
        } catch (error) {
          toast.error('Error Retrieving Backups')
        }
      }

      getBackUpComments()

      setFormNo(formNo + 1)
    } else if (formNo === 2) {
      if (selectedOption === 'date' && !selectedDate) {
        toast.error('Please select a Back Up')
        return
      }
      // TODO - ESLINT FIX ME
      // eslint-disable-next-line eqeqeq
      if (selectedOption === 'comment' && selectedComment.value == '') {
        toast.error('Please select a Back Up')
        return
      }

      // Construct the step two response based on the selected option and comment
      const stepTwoRes = {
        step: formNo,
        response: [
          selectedOption === 'date' ? selectedDate : selectedComment.value,
        ],
      }

      // Find the existing response for step 2 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepTwoRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepTwoRes,
        ])
      }

      // Reset state where policies are store before called
      setData([])

      const policyRequest = async () => {
        setLoadingState(true)

        const controller = new AbortController()
        setAbortController(controller)

        const ctId = formResponses.find(response => response.step === 1)
          ?.response[0]?.clientTenantId
        const currentSelectedOption = selectedOption
        const currentSelectedDate = selectedDate
        const currentComment = selectedComment.value
        // const currentComment = comment;

        try {
          let policyRes

          if (currentSelectedOption === 'date') {
            const burid = currentSelectedDate.key
            // const burid = +formResponses[1].response[0].key

            policyRes = await Axios.post(
              `${process.env.REACT_APP_MIDDLEWARE_URL}/get-policies-by-backuprunid`,
              {
                ctId,
                burid,
              },
              { signal: controller.signal }
            )
          } else {
            policyRes = await Axios.post(
              `${process.env.REACT_APP_MIDDLEWARE_URL}/get-policies-by-comment`,
              {
                ctId,
                comment: currentComment,
              },
              { signal: controller.signal }
            )
          }

          if (Object.keys(policyRes.data).length > 0) {
            setData(policyRes.data)
          } else {
            throw new Error('Failed to fetch policies')
          }
        } catch (error) {
          toast.error('Error Retrieving Policies')
        } finally {
          setLoadingState(false)
        }
      }

      policyRequest()

      setFormNo(formNo + 1)
    } else if (formNo === 3) {
      if (selectedObjects.length === 0) {
        return
      }

      const stepThreeRes = { step: formNo, response: selectedObjects }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepThreeRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepThreeRes,
        ])
      }

      // Set policy type ids, this is hooked to useEffect so it tracks what ptids user has selected
      const policyArray = [...selectedObjects]

      const getPolicyIds = policyArray.map(item => item.policyTypeId)

      setSelectedPolicies(getPolicyIds)

      setFormNo(formNo + 1)
    } else if (formNo === 4) {
      // I don't really know what's going on, the choices object that was here previously
      // was being initialised but never updated so I've removed it
      const stepFourRes = { step: formNo, response: {} }

      // Find the existing response for step 1 in the formResponses array
      const existingResponseIndex = formResponses.findIndex(
        response => response.step === formNo
      )

      if (existingResponseIndex !== -1) {
        // If an existing response exists, replace it with the latest response
        const updatedResponses = [...formResponses]
        updatedResponses[existingResponseIndex] = stepFourRes
        setFormResponses(updatedResponses)
      } else {
        // Otherwise, add the response to the array
        setFormResponses(prevFormResponses => [
          ...prevFormResponses,
          stepFourRes,
        ])
      }

      setFormNo(formNo + 1)
    } else {
      toast.error('Please complete all input fields')
    }
  }

  const pre = () => {
    if (abortController) {
      abortController.abort()
    }
    if (formNo === 2) {
      // Reset form responses and policy picker if the user goes back to the second step
      setFormResponses([])
      setOpenDropdowns([])
      setSelectedObjects([])
      setCheckedItems({})
      setSelectAll(false)
      setSelectedDate(null)
    } else if (formNo === 3) {
      // Clear selected policies if the user goes back from the third step
      setSelectedObjects([])
      setCheckedItems({})
      setSelectAll(false)
    }

    setFormNo(formNo - 1)
  }

  const finalSubmit = () => {
    setOpenPopup(true)

    const clientTenantId = +formResponses[0].response[0].clientTenantId

    const restoreBody = []

    formResponses[2].response.forEach(response => {
      const { policyGuid } = response
      const { policyTypeId } = response
      const { policyId } = response
      const policyName = response.PolicyName

      let additionalSettingsForPolicy = getFlagsForPolicy(
        additionalSettings,
        policyTypeId
      )

      // Exclude the restorePriorityHigh flag if overwriteExistingPolicy is set to false
      if (
        additionalSettingsForPolicy &&
        !additionalSettingsForPolicy.overwriteExistingPolicy
      ) {
        const { restorePriorityHigh, ...rest } = additionalSettingsForPolicy
        additionalSettingsForPolicy = rest
      }

      const eachPolicy = {
        clientTenantId,
        policyTypeId,
        policyGuid,
        policyId,
        policyName,
        ...additionalSettingsForPolicy,
      }

      restoreBody.push(eachPolicy)
    })

    const restorationRequest = async () => {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/send-restoration`,
          restoreBody
        )
        const { confirmationData } = response.data
        const { errorMessages } = response.data

        setBackEndErrors(errorMessages)
        setFinalResponse(confirmationData)
        refetchNotifications()
      } catch (error) {
        setBackEndErrors(error)
      }
    }

    restorationRequest()
  }

  // Timeline step text
  const stepH1 = 'Restore'
  const stepH2 = 'Select Restore Point'
  const stepH3 = 'Select Policies'
  const stepH4 = 'Additional Settings'
  const stepH5 = 'Confirm Changes'

  const stepP1 = 'Select the tenant to restore.'
  const stepP2 = 'Choose a point in time to restore polices from.'
  const stepP3 = 'Select the policies you would like to restore.'
  const stepP4 = 'Select how your policies should be restored.'
  const stepP5 = 'Confirm you are happy to proceed with restore.'

  return (
    <div className='ui-panel restore-journey'>
      <div className='heading'>
        <h2>Restore</h2>
        <p>Restore Policies to your chosen tenant</p>
      </div>
      <div className='action-body'>
        <div className='progress-bar'>
          {formArray.map((v, i) => (
            <div key={uuidv4()}>
              <div className='flex'>
                <div className='flex'>
                  <div
                    className={`progress-number progress-number-${v} ${
                      formNo - 1 === i ||
                      formNo - 1 === i + 1 ||
                      formNo - 1 === i + 2 ||
                      formNo - 1 === i + 3 ||
                      formNo === formArray.length
                        ? 'cyan-bg text-white'
                        : 'bg-slate-300'
                    }`}
                  >
                    {v}
                  </div>
                  <div className='progress-text'>
                    <p className='navy-text'>
                      <strong>
                        {(v === 1 && stepH1) ||
                          (v === 2 && stepH2) ||
                          (v === 3 && stepH3) ||
                          (v === 4 && stepH4) ||
                          (v === 5 && stepH5)}
                      </strong>
                    </p>
                    <p className='text-gray-400'>
                      {(v === 1 && stepP1) ||
                        (v === 2 && stepP2) ||
                        (v === 3 && stepP3) ||
                        (v === 4 && stepP4) ||
                        (v === 5 && stepP5)}
                    </p>
                  </div>
                </div>
              </div>
              {i !== formArray.length - 1 && (
                <div className='progress-bar-link' />
              )}
            </div>
          ))}
        </div>
        <div className='action-body-form'>
          {formNo === 1
            ? !isLegacyTenantsLoading &&
              !isLegacyTenantsError && (
                <>
                  <ListTable
                    selectedListItems={selectedSourceTenant}
                    setSelectedListItems={setSelectedSourceTenant}
                    uniqueKey='clientTenantId'
                    columns={tenantsColumns}
                    data={legacyTenants ?? []}
                    enableMultiRowSelection={false}
                    loading={isLegacyTenantsLoading && isTenantTagsLoading}
                    enableRowSelection
                  />

                  <div className='mt-6 gap-3 flex'>
                    <button
                      type='button'
                      onClick={next}
                      disabled={selectedSourceTenant.length === 0}
                      className='btn cyan-btn'
                    >
                      Next
                    </button>
                  </div>
                </>
              )
            : isLegacyTenantsError && (
                <Alert
                  type='error'
                  title={legacyTenantsError.response.data.message}
                  margin='mb-4'
                >
                  {legacyTenantsError.response.data.errors?.map(error => (
                    <ul>
                      <li>{error}</li>
                    </ul>
                  ))}
                </Alert>
              )}
          {formNo === 2 &&
            (loadingState ? (
              <TenantSkeleton loadingTitle='Loading Backups...' />
            ) : (
              <>
                <h4 className='mb-4'>Select a Back Up type</h4>
                <div className='flex justify-between mb-[50px]'>
                  <label
                    className={`w-[48%] cursor-pointer rounded-md p-3 text-gray-600 ring-2 transition-all hover:shadow  ${
                      selectedOption === 'date'
                        ? 'cyan-text ring-blue-400 bg-white'
                        : 'text-gray-700 ring-slate-100 light-grey-bg'
                    }`}
                  >
                    <input
                      type='radio'
                      name='option'
                      value='date'
                      checked={selectedOption === 'date'}
                      onChange={() => setSelectedOption('date')}
                      className='peer sr-only'
                    />
                    <div className='flex items-center justify-between'>
                      <p className='font-semibold text-slate-700'>Automated</p>
                      <svg width='24' height='24' viewBox='0 0 24 24'>
                        <path
                          fill='currentColor'
                          d='m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z'
                        />
                      </svg>
                    </div>
                  </label>

                  <label
                    className={`w-[48%] cursor-pointer rounded-md p-3 text-gray-600 ring-2 transition-all hover:shadow ${
                      selectedOption === 'comment'
                        ? 'cyan-text ring-blue-400 bg-white'
                        : 'text-gray-700 ring-slate-100 light-grey-bg'
                    }`}
                  >
                    <input
                      type='radio'
                      name='option'
                      value='comment'
                      checked={selectedOption === 'comment'}
                      onChange={() => setSelectedOption('comment')}
                      className='peer sr-only'
                    />
                    <div className='flex items-center justify-between'>
                      <p className='font-semibold text-slate-700'>Manual</p>
                      <svg width='24' height='24' viewBox='0 0 24 24'>
                        <path
                          fill='currentColor'
                          d='m10.6 13.8l-2.175-2.175q-.275-.275-.675-.275t-.7.3q-.275.275-.275.7q0 .425.275.7L9.9 15.9q.275.275.7.275q.425 0 .7-.275l5.675-5.675q.275-.275.275-.675t-.3-.7q-.275-.275-.7-.275q-.425 0-.7.275ZM12 22q-2.075 0-3.9-.788q-1.825-.787-3.175-2.137q-1.35-1.35-2.137-3.175Q2 14.075 2 12t.788-3.9q.787-1.825 2.137-3.175q1.35-1.35 3.175-2.138Q9.925 2 12 2t3.9.787q1.825.788 3.175 2.138q1.35 1.35 2.137 3.175Q22 9.925 22 12t-.788 3.9q-.787 1.825-2.137 3.175q-1.35 1.35-3.175 2.137Q14.075 22 12 22Z'
                        />
                      </svg>
                    </div>
                  </label>
                </div>

                {selectedOption === 'date' && (
                  <>
                    <h4 className='mb-4'>Select a Back Up by date</h4>

                    <DatePicker
                      dates={dates}
                      setDates={setDates}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                    />
                  </>
                )}
                {selectedOption === 'comment' && (
                  <>
                    <h4 className='mb-4'>Select a Back Up by comment</h4>

                    <BackUpCommentPicker
                      dates={comment}
                      selectedComment={selectedComment}
                      setSelectedComment={setSelectedComment}
                    />
                  </>
                )}
                <div className='mt-6 gap-3 flex'>
                  <button type='button' onClick={pre} className='btn navy-btn'>
                    Previous
                  </button>
                  <button
                    type='button'
                    disabled={
                      (selectedOption === 'date' && !selectedDate) ||
                      (selectedOption === 'comment' &&
                        selectedComment?.value === '')
                    }
                    onClick={next}
                    className='btn cyan-btn'
                  >
                    Next
                  </button>
                </div>
              </>
            ))}
          {formNo === 3 && (
            <div className='max-w-[452px]'>
              {loadingState ? (
                <PolicyPickerSkeleton loadingTitle='Loading policies...' />
              ) : (
                <>
                  <h4>Select policies to Restore</h4>
                  <RestorePolicyPicker
                    data={data}
                    checkedItems={checkedItems}
                    showChildren={showChildren}
                    selectAll={selectAll}
                    handleParentCheckboxChange={handleParentCheckboxChange}
                    handleChildCheckboxChange={handleChildCheckboxChange}
                    handleSelectAll={handleSelectAll}
                    handleDropdownToggle={handleDropdownToggle}
                    selectedTargetTenant={selectedSourceTenant}
                  />
                </>
              )}

              <div className='mt-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
                <button
                  type='button'
                  onClick={next}
                  disabled={selectedObjects.length === 0}
                  className='btn cyan-btn'
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {formNo === 4 && (
            <>
              <h3 className='mb-4'>Additional Settings</h3>
              <AdditionalDeploymentOptions
                additionalSettingsConfig={additionalSettings.filter(setting =>
                  selectedPolicies.includes(setting.policyId)
                )}
                onChange={(policyId, flagName, newValue) => {
                  setAdditionalSettings(prevState =>
                    updateAdditionalSettingValue(
                      prevState,
                      policyId,
                      flagName,
                      newValue
                    )
                  )
                }}
                onNestedFlagChange={(
                  policyId,
                  parentFlagName,
                  nestedFlagName,
                  newValue
                ) => {
                  setAdditionalSettings(prevState =>
                    updateNestedAdditionalSetting(
                      prevState,
                      policyId,
                      parentFlagName,
                      nestedFlagName,
                      newValue
                    )
                  )
                }}
              />
              <div className='mt-6 gap-3 flex'>
                <button type='button' onClick={pre} className='btn navy-btn'>
                  Previous
                </button>
                <button type='button' onClick={next} className='btn cyan-btn'>
                  Next
                </button>
              </div>
            </>
          )}
          {formNo === 5 && (
            <div>
              <div className='final-confirmation'>
                <h3>Review and confirm your changes</h3>

                <div className='mt-4'>
                  <RestoreSummary formResponses={formResponses} />
                  <ReviewSummaryContent
                    additionalPolicySettings={additionalSettings.filter(
                      setting => selectedPolicies.includes(setting.policyId)
                    )}
                  />
                </div>
                <div className='mt-4 gap-3 flex'>
                  <button type='button' onClick={pre} className='btn navy-btn'>
                    Previous
                  </button>
                  <button
                    type='button'
                    onClick={finalSubmit}
                    className='btn cyan-btn'
                  >
                    Submit
                  </button>
                </div>
                {openPopup === true && (
                  <RestorePopup
                    finalResponse={finalResponse}
                    formResponses={formResponses}
                    backEndErrors={backEndErrors}
                    additionalSettings={additionalSettings}
                    selectedPolicies={selectedPolicies}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Restore
