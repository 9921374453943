import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const DatePicker = ({ dates, selectedDate, setSelectedDate }) => {
  const dateClick = (key, value) => {
    if (selectedDate && selectedDate.key === key) {
      setSelectedDate(null)
    } else {
      const updatedDate = { key, value }
      setSelectedDate(updatedDate)
    }
  }

  const sortedArray = Object.entries(dates).sort(
    ([keyA], [keyB]) => keyB - keyA
  )

  return (
    <div>
      <div className='table-container'>
        <table>
          <tbody>
            <tr className='table-head'>
              {/* <td>Date Index</td> */}
              <td>Back Up Date</td>
              <td aria-label='Is Selected' />
            </tr>
            {sortedArray.map(([key, value]) => (
              <tr
                key={key}
                onClick={() => dateClick(parseInt(key), value)}
                className={`date-row cursor-pointer ${
                  selectedDate && selectedDate.key === parseInt(key)
                    ? 'selected'
                    : ''
                }`}
              >
                {/* <td className="date-key">{key}</td> */}
                <td className='date-value'>{value}</td>
                <td className='check-cell'>
                  {selectedDate && selectedDate.key === parseInt(key) ? (
                    <FontAwesomeIcon icon={faCheckCircle} className='ml-2' />
                  ) : null}
                </td>
              </tr>
            ))}

            {sortedArray === 0 && (
              <tr>
                <td colSpan='3'>No dates found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DatePicker
