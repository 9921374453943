import { PropTypes } from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ConfirmationPopupSkeleton = ({ loadingTitle }) => (
  <div className='confirmation-popup-spinner'>
    <div className='w-full'>
      <p className='mb-2'>
        <Skeleton count={1} />
        <Skeleton count={1} width={180} />
      </p>
      <p className='mb-2'>
        <Skeleton count={2} />
      </p>
      <p className='mb-2'>
        <Skeleton count={1} />
        <Skeleton count={1} width={180} />
      </p>
      <p className='mb-2'>
        <Skeleton count={2} />
      </p>
      <p className='mb-8'>
        <Skeleton count={1} />
        <Skeleton count={1} width={180} />
      </p>

      <h4 className='text-center'>{loadingTitle}</h4>
    </div>
  </div>
)

ConfirmationPopupSkeleton.propTypes = {
  loadingTitle: PropTypes.string.isRequired,
}

export default ConfirmationPopupSkeleton
