import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import ManageTenantsTable from '../components/ManageTenantsTable'
import TrackChangesEmailInputs from '../components/TrackChangesEmailInputs/TrackChangesEmailInputs'
import TabSelector from '../components/TabSelector'
import Alert from '../components/Alert/Alert'
import Checkbox from '../components/Checkbox'
import BaselineManagerLegacy from '../components/BaselineGroups/components/BaselineManagerLegacy'

const tabOptions = [
  {
    name: 'Tenants',
    value: 'tenants',
  },
  {
    name: 'Baselines',
    value: 'baselines',
  },
]

const ManageTenantsLegacy = () => {
  const [params, setSearchParams] = useSearchParams()

  const selectedTab = params.get('tab') || 'tenants'

  const [targetTenantQuery, setTargetTenantQuery] = useState('')
  const [targetTenants, setTargetTenants] = useState([])
  const [allTenants, setAllTenants] = useState([])
  const [partnerCenterOnly, setPartnerCenterOnly] = useState(false)

  const filteredTenants = useMemo(
    () =>
      targetTenants
        .filter(e => !partnerCenterOnly || e.partnerCenter === true)
        .filter(tenant =>
          tenant.tenantFriendlyName
            .toLowerCase()
            .includes(targetTenantQuery.toLowerCase())
        ),
    [targetTenants, targetTenantQuery, partnerCenterOnly]
  )

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await Axios.get(
          `${process.env.REACT_APP_MIDDLEWARE_URL}/list-tenants`
        )
        const data = response.data.Data

        setAllTenants(data)
        setTargetTenants(data)
      } catch (error) {
        toast.error('Error Retrieving Tenants')
      }
    }

    fetchTenants()
  }, [])

  const headerText =
    selectedTab === 'tenants' ? 'Manage Tenants' : 'Manage Baselines'

  const buildTab = () => {
    switch (selectedTab) {
      case 'tenants':
        return (
          <>
            <TrackChangesEmailInputs />

            <Alert title='Please note' type='info' margin='my-6'>
              <p>
                When refreshing permissions on existing tenants, you must sign
                into the relevant global admin account for that tenant. Please
                only perform this if instructed to do so by support.
              </p>
            </Alert>

            <h4>
              <b>Tenants enrolled: {allTenants.length}</b>
            </h4>

            <div className='mt-4'>
              <Checkbox
                id='partnerCenterOnlyCheckbox'
                checked={partnerCenterOnly}
                label='Show Partner Center tenants only'
                onChange={setPartnerCenterOnly}
              />
            </div>
            <ManageTenantsTable
              targetTenants={filteredTenants}
              targetTenantQuery={targetTenantQuery}
              setTargetTenantQuery={setTargetTenantQuery}
            />
          </>
        )
      case 'baselines':
        return <BaselineManagerLegacy />
      default:
        return null
    }
  }

  return (
    <div className='ui-panel tenant-management'>
      <div className='heading'>
        <div className='flex justify-between flex-wrap'>
          <h2 className='mr-4'>{headerText}</h2>
          <TabSelector
            value={selectedTab}
            onChange={newTab => setSearchParams({ tab: newTab })}
            options={tabOptions}
          />
        </div>
      </div>
      <div className='action-body no-progress-bar'>{buildTab()}</div>
    </div>
  )
}

export default ManageTenantsLegacy
