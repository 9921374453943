import { bool, func, node, string } from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const Modal = ({
  children,
  isOpen,
  setModalOpen,
  closeOnBlur,
  dialogPanelWidth,
}) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      as='div'
      className='relative z-20 w-2/3'
      onClose={closeOnBlur ? () => setModalOpen(false) : () => {}}
    >
      <Transition.Child
        as={Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='fixed inset-0 bg-black/25' />
      </Transition.Child>

      <div
        className={`fixed inset-0 overflow-y-auto z-10 ${isOpen ? 'backdrop-blur-sm' : ''}`}
      >
        <div className='flex min-h-full items-center justify-center p-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel
              className={`w-2/5 ${dialogPanelWidth} transform rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all`}
            >
              {children}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
)

Modal.defaultProps = {
  closeOnBlur: true,
  dialogPanelWidth: '',
}

Modal.propTypes = {
  children: node.isRequired,
  isOpen: bool.isRequired,
  setModalOpen: func.isRequired,
  closeOnBlur: bool,
  dialogPanelWidth: string,
}

export default Modal
