import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const useGetListPolicies = ctId =>
  useQuery({
    queryKey: ['policyData', ctId],
    queryFn: async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/policies`,
        {
          params: {
            clientTenantId: ctId,
          },
        }
      )

      return response.data
    },
  })

export default useGetListPolicies
