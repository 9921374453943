import { arrayOf, shape, string } from 'prop-types'
import PolicySubGroup from '../PolicySubGroup/PolicySubGroup'
import ExpandButton from '../components/ExpandButton'
import Accordion from '../components/Accordion'
import { useExpansion } from '../context/ExpansionContext'
import { useSelection } from '../context/SelectionContext'

/**
 * PolicyGroup component
 * @param {string} title - product name
 * @param {Array<{name: string, categories: Array<{name: string, items: Array<{name: string, type: string}>}>}>} subGroups - subGroups
 * @param {string} icon - product icon
 */
const PolicyGroup = ({ title, subGroups, icon }) => {
  const { isExpanded, toggleExpand } = useExpansion()
  const {
    state: { selectedPolicies },
    selectGroup,
    getGroupSelectedItemsCount,
  } = useSelection()

  const policiesInGroup = subGroups.flatMap(subGroup =>
    subGroup.categories.flatMap(category =>
      category.items.filter(policy => !policy.readOnly)
    )
  )

  const selectAllInGroup = policiesInGroup.every(policy =>
    selectedPolicies.some(selectedPolicy => selectedPolicy.id === policy.id)
  )

  const selectedPoliciesInGroup = getGroupSelectedItemsCount(policiesInGroup)

  const handleAllGroupSelect = () => {
    selectGroup(policiesInGroup, !selectAllInGroup)
  }

  const handleToggleExpand = () => {
    toggleExpand(title)
  }

  const isGroupExpanded = isExpanded(title)

  return (
    <div
      className={`cursor-pointer mb-5 rounded-sm bg-neutral-50 ${isGroupExpanded ? 'border' : ''} drop-shadow-sm`}
    >
      <Accordion
        handleSelectAll={handleAllGroupSelect}
        toggleExpand={handleToggleExpand}
        selectAll={selectAllInGroup}
        tooltipText={`Select all in ${title}`}
      >
        <img src={icon} alt={title} className='w-8 h-8 mr-2' />
        <span className='font-bold text-lg'>
          {title} ({policiesInGroup.length})
          {selectedPoliciesInGroup > 0 &&
            ` - ${selectedPoliciesInGroup} ${selectedPoliciesInGroup === 1 ? 'policy' : 'policies'} selected`}
        </span>
        <div className='ml-auto flex items-center'>
          <ExpandButton isExpanded={isGroupExpanded} />
        </div>
      </Accordion>

      {isGroupExpanded && (
        <div className='p-4'>
          {subGroups.map(subGroup => (
            <PolicySubGroup
              key={subGroup.name}
              parentGroup={title}
              subGroup={subGroup}
            />
          ))}
        </div>
      )}
    </div>
  )
}

PolicyGroup.defaultProps = {
  icon: '',
}

PolicyGroup.propTypes = {
  title: string.isRequired,
  subGroups: arrayOf(
    shape({
      name: string.isRequired,
      categories: arrayOf(
        shape({
          name: string.isRequired,
          items: arrayOf(
            shape({
              name: string.isRequired,
              type: string.isRequired,
            })
          ),
        })
      ),
    })
  ).isRequired,
  icon: string,
}

export default PolicyGroup
