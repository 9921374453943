import {
  func,
  string,
  bool,
  shape,
  number,
  oneOfType,
  arrayOf,
} from 'prop-types'

import Select from 'react-select'

import CustomTagsOption from './CustomTagsOption'
import CustomTagsDisplayValues from './CustomTagsDisplayValues'
import { tenantTagsShape } from '../helpers'

const TenantTagsSelect = ({
  tenantTags,
  selectedTenantTags,
  setSelectedTenantTags,
  hideLabel,
}) => {
  const options = tenantTags?.map(tag => ({
    label: tag.tag,
    value: tag.id,
    description: tag.description,
    colour: tag.colour,
  }))

  return (
    <div className='flex items-center mt-1'>
      {!hideLabel && (
        <label htmlFor='selectedTenantTags' className='w-[200px] mr-2'>
          <b>Filter by tenant tags</b>
        </label>
      )}
      <div
        id='selectedTenantTags'
        aria-labelledby='Filter by tenant tags'
        className='w-[100%] focus:outline-blue-400 rounded-md text-gray-700 text-left'
      >
        <Select
          value={selectedTenantTags}
          options={options}
          isMulti
          onChange={setSelectedTenantTags}
          closeMenuOnSelect={false}
          components={{
            Option: CustomTagsOption,
            MultiValue: CustomTagsDisplayValues,
            MultiValueLabel: () => null,
            MultiValueRemove: () => null,
            MultiValueContainer: () => null,
          }}
          placeholder='Select tags'
        />
      </div>
    </div>
  )
}

TenantTagsSelect.defaultProps = {
  hideLabel: false,
}

TenantTagsSelect.propTypes = {
  tenantTags: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
      colour: string,
    })
  ).isRequired,
  setSelectedTenantTags: func.isRequired,
  selectedTenantTags: arrayOf(tenantTagsShape).isRequired,
  hideLabel: bool,
}

export default TenantTagsSelect
