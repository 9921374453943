import NavLinks from './NavLinks'
import lightModeLogo from '../assets/inforcer-logo.png'

const DesktopSidebar = () => (
  <nav className='desktop-sidebar dark:bg-slate-800 dark:border-r-sky-800'>
    <img src={lightModeLogo} className='inforcer-logo' alt='Inforcer Logo' />
    <NavLinks />
  </nav>
)

export default DesktopSidebar
