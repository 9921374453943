import { useState, useEffect } from 'react'
import { number, bool, func } from 'prop-types'
import { Pagination as MUIPagination } from '@mui/material'

const Pagination = ({
  totalItems,
  itemsPerPage,
  onPageChange,
  currentPage,
  disabled,
}) => {
  const [page, setPage] = useState(currentPage || 1)
  const totalPages = Math.ceil(totalItems / itemsPerPage)

  useEffect(() => {
    onPageChange(page)
  }, [page, onPageChange])

  useEffect(() => {
    setPage(1) // Reset to first page when totalItems or itemsPerPage change
  }, [totalItems, itemsPerPage])

  const handleChange = (event, value) => {
    setPage(value)
  }

  if (totalPages === 0) return null

  return (
    <div id='mui-pagination' className='flex justify-center mt-4 pb-8'>
      <MUIPagination
        count={totalPages}
        page={page}
        onChange={handleChange}
        color='primary'
        disabled={disabled}
      />
    </div>
  )
}

Pagination.defaultProps = {
  currentPage: 1,
  disabled: false,
}

Pagination.propTypes = {
  totalItems: number.isRequired,
  itemsPerPage: number.isRequired,
  onPageChange: func.isRequired,
  currentPage: number,
  disabled: bool,
}

export default Pagination
