import { useState, useEffect, useRef } from 'react'
import { string, func, arrayOf, shape } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

/**
 * SearchBar component
 * @param {string} searchTerm - search term
 * @param {function} setSearchTerm - function to set search term
 * @param {Array<{id: string, displayName: string}>} suggestions - suggestions
 * @returns {JSX.Element}
 */
const SearchBar = ({ searchTerm, setSearchTerm, suggestions }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const searchBarRef = useRef(null)

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredSuggestions([])
      setShowSuggestions(false)
    } else {
      const filtered = suggestions.filter(suggestion =>
        suggestion?.displayName
          ?.toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
      )
      setFilteredSuggestions(filtered)
      setShowSuggestions(true)
    }
  }, [searchTerm, suggestions])

  // Close suggestions when clicking outside the search bar
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowSuggestions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleChange = e => {
    setSearchTerm(e.target.value)
    setShowSuggestions(true)
  }

  const handleClick = suggestion => {
    setSearchTerm(suggestion.displayName)
    setFilteredSuggestions([])
    setShowSuggestions(false)
  }

  const suggestionsListComponent = showSuggestions && searchTerm && (
    <div className='absolute bg-white border border-gray-300 mt-1 rounded-sm shadow-lg z-10 left-0'>
      {filteredSuggestions.length ? (
        <div className='flex flex-col'>
          {filteredSuggestions.map(suggestion => (
            <div className='p-2' key={suggestion.id}>
              <button
                type='button'
                onClick={() => handleClick(suggestion)}
                className='text-sm flex text-left items-center'
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className='mr-2 text-gray-400'
                />
                {suggestion.displayName}
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className='grid gap-4 p-4 text-sm text-gray-500'>
          No suggestions available
        </div>
      )}
    </div>
  )

  return (
    <div className='relative' ref={searchBarRef}>
      <div className='relative'>
        <div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
          <FontAwesomeIcon icon={faSearch} className='text-gray-500' />
        </div>
        <input
          type='text'
          id='default-search'
          onChange={handleChange}
          value={searchTerm}
          placeholder='Search policies'
          className='block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-sm focus:border-customGray dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
          aria-label='Search policies'
        />
      </div>
      {suggestionsListComponent}
    </div>
  )
}

SearchBar.propTypes = {
  searchTerm: string.isRequired,
  setSearchTerm: func.isRequired,
  suggestions: arrayOf(
    shape({
      id: string.isRequired,
      displayName: string.isRequired,
    })
  ).isRequired,
}

export default SearchBar
