import PropTypes from 'prop-types'
import { useState } from 'react'
import setDarkModeClasses, {
  DARK_MODE_OPT,
  themeLocalStorageKey,
} from '../../utils/SetAppTheme'

const RadioWithLabel = ({ onChange, children, inputId, selected }) => (
  <p className='flex items-center'>
    <input
      type='radio'
      name='size'
      id={inputId}
      checked={selected}
      value='medium'
      onChange={onChange}
    />
    <label className='pl-2 text-lg' htmlFor={inputId}>
      {children}
    </label>
  </p>
)

RadioWithLabel.defaultProps = {
  selected: false,
}

RadioWithLabel.propTypes = {
  onChange: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  selected: PropTypes.bool,
}

const DarkModeControl = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem(themeLocalStorageKey)
  )

  return (
    <fieldset className='gap-2 flex flex-col'>
      <legend>
        <h4 className='mb-2'>Dark Mode</h4>
      </legend>

      <RadioWithLabel
        onChange={() => {
          setDarkMode(null)
          localStorage.removeItem(themeLocalStorageKey)
          setDarkModeClasses()
        }}
        inputId='dark_mode_pref_1'
        selected={darkMode === null}
      >
        Use OS preferences
      </RadioWithLabel>
      <RadioWithLabel
        onChange={() => {
          setDarkMode(DARK_MODE_OPT.light)
          localStorage.setItem(themeLocalStorageKey, DARK_MODE_OPT.light)
          setDarkModeClasses()
        }}
        inputId='dark_mode_pref_2'
        selected={darkMode === DARK_MODE_OPT.light}
      >
        Light
      </RadioWithLabel>
      <RadioWithLabel
        onChange={() => {
          setDarkMode(DARK_MODE_OPT.dark)
          localStorage.setItem(themeLocalStorageKey, DARK_MODE_OPT.dark)
          setDarkModeClasses()
        }}
        inputId='dark_mode_pref_3'
        selected={darkMode === DARK_MODE_OPT.dark}
      >
        Dark
      </RadioWithLabel>
    </fieldset>
  )
}

export default DarkModeControl
