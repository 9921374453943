import { useState, useEffect } from 'react'
import { arrayOf, string, shape } from 'prop-types'
import { Tooltip } from '@mui/material'

import { useSelection } from '../context/SelectionContext'
import PolicySubGroupItem from '../PolicySubGroupItem/PolicySubGroupItem'
import ExpandButton from '../components/ExpandButton'
import { useExpansion } from '../context/ExpansionContext'
import { filterOutReadOnly } from '../utils/utils'

/**
 * PolicyCategory Component
 *
 * This component renders a list of policies under a specified category. It displays the top-level category name and iterates through any subgroup items to show relevant policies within those subgroups.
 *
 * @param {Object} props.category - The category object containing the top-level category name and associated subgroup items.
 * @param {string} props.category.name - The name of the top-level policy category.
 * @param {Array} props.category.subgroups - A list of subgroup items under the main category. Each subgroup item can contain policies specific to that subgroup.
 * @returns {JSX.Element} The rendered component displaying the policies for the specified category.
 */

const PolicyCategory = ({ category }) => {
  const { isExpanded, toggleExpand } = useExpansion()
  const {
    state: { selectedPolicies },
    selectGroup,
  } = useSelection()
  const [isGroupSelected, setIsGroupSelected] = useState(false)

  const policies = filterOutReadOnly(category.items)

  useEffect(() => {
    setIsGroupSelected(policies.every(id => selectedPolicies.includes(id)))
  }, [selectedPolicies, policies])

  const handleGroupSelect = () => {
    const newGroupSelectionState = !isGroupSelected
    setIsGroupSelected(newGroupSelectionState)

    selectGroup(policies, newGroupSelectionState)
  }

  return (
    <div
      className={`mb-5 bg-white rounded ${isExpanded(category.name) ? 'border' : ''}`}
    >
      <button
        type='button'
        className='flex items-center p-4 bg-neutral-50 cursor-pointer w-full text-left rounded'
        onClick={() => toggleExpand(category.name)}
      >
        <Tooltip title={`Select all in ${category.name}`}>
          <input
            type='checkbox'
            className='mr-4'
            checked={isGroupSelected}
            onChange={handleGroupSelect}
            onClick={e => e.stopPropagation()}
          />
        </Tooltip>
        <span className='font-semibold'>{category.name}</span>
        <div className='ml-auto flex items-center'>
          <ExpandButton isExpanded={isExpanded(category.name)} />
        </div>
      </button>
      {isExpanded(category.name) && (
        <div className='bg-white p-2'>
          <div className='flex justify-between p-2'>
            <span className='text-gray-600 text-sm'>Policy Name</span>
            <span className='text-gray-600 text-sm'>Policy JSON</span>
          </div>
          <div>
            {category.items.map(policy => (
              <PolicySubGroupItem key={policy.name} item={policy} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

PolicyCategory.propTypes = {
  category: shape({
    name: string,
    items: arrayOf(
      shape({
        name: string,
        type: string,
      })
    ),
  }).isRequired,
}

export default PolicyCategory
