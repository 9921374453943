import { bool } from 'prop-types'
import {
  faPenToSquare,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import BaselineEditor from './BaselineEditor'
import { useTenantGroups } from '../../../hooks/UseTenantGroups'
import useGetTenants from '../../SharedBaselines/api/useGetTenants'
import PopupDialog from '../../PopupDialog'

/**
 * @param {{
 *  tenants: any[];
 *  addGroup: boolean;
 * }} props
 */
const BaselineManagerLegacy = ({ addGroup = false }) => {
  const [search, setSearch] = useState('')

  const [addingBaseline, setAddingBaseline] = useState(addGroup)

  const [selectedBaselineId, setSelectedBaselineId] = useState()

  const [groupIdToDelete, setGroupIdToDelete] = useState()

  const { error, loading, groups, saveGroup, deleteGroup } = useTenantGroups()

  const {
    data: tenants,
    isLoading: isTenantsLoading,
    isError: isTenantsError,
  } = useGetTenants()

  const filteredGroups = useMemo(() => {
    const output = groups?.filter(e => e.name.toLowerCase().includes(search))
    output?.sort((a, b) => {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
    return output
  }, [groups, search])

  const selectedBaseline = useMemo(
    () =>
      addingBaseline
        ? { name: '', clientTenantIds: [] }
        : groups.find(
            group => group.clientTenantGroupId === selectedBaselineId
          ),
    [addingBaseline, groups, selectedBaselineId]
  )

  const clearSelection = () => {
    setSelectedBaselineId(null)
    setAddingBaseline(false)
  }

  const buildBaselineList = () => (
    <>
      <div className='flex items-center'>
        <input
          className='flex w-[350px] focus:outline-blue-400 rounded-md light-grey-bg p-2 text-gray-700 mb-[20px] mt-[20px]'
          type='text'
          placeholder='Search'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <button
          type='button'
          className='btn navy-btn clear-btn mr-4'
          onClick={() => setSearch('')}
        >
          Clear
        </button>
        <button
          type='button'
          className='btn cyan-btn flex items-center'
          onClick={() => setAddingBaseline(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Add Group
        </button>
      </div>
      <div className='table-container mb-6'>
        <table>
          <tbody>
            <tr className='table-head'>
              <td>Baseline Name</td>
              <td aria-label='Actions' />
            </tr>
            {filteredGroups.map(group => (
              <tr
                key={group.clientTenantGroupId}
                className='tenant-row cursor-pointer'
              >
                <td>{group.name}</td>
                <td className='check-cell text-center'>
                  <button
                    type='button'
                    className='btn cyan-btn mr-3'
                    onClick={() =>
                      setSelectedBaselineId(group.clientTenantGroupId)
                    }
                  >
                    <FontAwesomeIcon icon={faPenToSquare} /> Edit
                  </button>
                  <button
                    type='button'
                    className='btn navy-btn'
                    onClick={() =>
                      setGroupIdToDelete(group.clientTenantGroupId)
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </td>
              </tr>
            ))}
            {filteredGroups.length === 0 ? (
              <tr>
                <td colSpan={2}>No baselines found</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  )

  if (isTenantsLoading) {
    return <p>Loading...</p>
  }

  if (isTenantsError) {
    return <p>Failed to load tenants</p>
  }

  return (
    <>
      {error ? <p className='text-red-500 mb-[24px]'>{error}</p> : null}

      {selectedBaseline ? (
        <BaselineEditor
          tenants={tenants.data}
          baseline={selectedBaseline}
          loading={loading}
          onClose={clearSelection}
          onSave={saveGroup}
        />
      ) : (
        buildBaselineList()
      )}

      <PopupDialog
        open={groupIdToDelete}
        onClose={() => setGroupIdToDelete(undefined)}
      >
        <h3 className='text-center mb-6'>Are you sure?</h3>
        <div className='text-center'>
          You are about to delete this group permanently, are you sure you want
          to continue?
          <br />
          <br />
          <b>This action cannot be reversed.</b>
        </div>
        <div className='footer mt-10 flex justify-between'>
          <button
            type='button'
            className='btn navy-btn mr-4'
            onClick={() => setGroupIdToDelete(undefined)}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn cyan-btn'
            onClick={() => {
              deleteGroup(groupIdToDelete)
              setGroupIdToDelete(undefined)
            }}
          >
            Confirm
          </button>
        </div>
      </PopupDialog>
    </>
  )
}

export default BaselineManagerLegacy

BaselineManagerLegacy.defaultProps = {
  addGroup: false,
}

BaselineManagerLegacy.propTypes = {
  addGroup: bool,
}
