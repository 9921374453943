import { useContext, useState } from 'react'
import Axios from 'axios'
import { toast } from 'react-toastify'
import { useFeatureFlag } from 'configcat-react'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { bool, node, string } from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import GlobalContext from '../contexts/GlobalContext'
import 'react-toastify/dist/ReactToastify.css'
import DarkModeControl from '../components/DarkModeToggle/DarkModeControl'
import Alert from '../components/Alert/Alert'
import SSOAdmin from '../components/sso/SSOAdmin'
import SSOUsers from '../components/sso/SSOUsers'
import TenantTagSettings from '../components/TenantTags/TenantTagSettings'

const tabValues = {
  user: 'user',
  sso: 'sso',
  userManagement: 'user-management',
  appearance: 'appearance',
  tenantTags: 'tenant-tags',
}

const TabPanel = ({ children, value, selected }) => (
  <div
    role='tabpanel'
    hidden={!selected}
    aria-labelledby={`tb-${value}`}
    className='settings-section mt-4'
  >
    {selected && children}
  </div>
)

TabPanel.propTypes = {
  children: node.isRequired,
  selected: bool.isRequired,
  value: string.isRequired,
}

const Settings = () => {
  const { loadingState, setLoadingState } = useContext(GlobalContext)
  const { value: enableDarkModeValue } = useFeatureFlag('enableDarkMode', false)
  const { section } = useParams()
  const navigate = useNavigate()

  const [oldPass, setOldPass] = useState('')
  const [newPass, setNewPass] = useState('')
  const [confirmNewPass, setConfirmNewPass] = useState('')
  const [passSuccess, setPassSuccess] = useState('')
  const [passError, setPassError] = useState([])

  const [selectedTab, setSelectedTab] = useState(section || 'user')
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
    navigate(`/settings/${newValue}`)
  }

  const { value: enableTenantTags } = useFeatureFlag('enableTenantTags', true)

  const isClientAdmin = localStorage.getItem('isClientAdmin') === 'true'
  const isSSOUser = localStorage.getItem('isSSOUser') === 'true'
  const savedUserName = localStorage.getItem('username')

  const sessionUsername = () => {
    if (savedUserName === null) {
      toast.info('Your session has expired. Please log back in.')

      setTimeout(() => {
        window.location.href = '/'
      }, 2000)
    }
  }

  // Password conditions
  const validPass = () => {
    const issues = []

    if (newPass !== confirmNewPass) {
      issues.push('New password fields do not match')
    }
    if (newPass.length < 8 || newPass.length > 25) {
      issues.push('New password should be between 8 -25 characters')
    }
    if (!/[A-Z]/.test(newPass)) {
      issues.push('New password should have at least 1 uppercase character')
    }
    if (!/\d/.test(newPass)) {
      issues.push('New password should have at least 1 number')
    }
    if (!/[^A-Za-z0-9]/.test(newPass)) {
      issues.push('New password should have at least 1 special character')
    }
    if (/\s/.test(newPass)) {
      issues.push('New password should not contain spaces')
    }

    if (issues.length) {
      setPassError(issues)
      setPassSuccess('')
      return false
    }

    setPassError([])
    return true
  }

  const handlePassChange = async () => {
    try {
      setLoadingState(true)

      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/change-user-pass`,
        {
          newPassword: newPass,
          password: oldPass,
          username: savedUserName,
        }
      )

      if (response.data.isError !== undefined) {
        const { isError } = response.data

        if (isError) {
          setPassError([response.data.message])
          setPassSuccess('')
        } else {
          setPassSuccess(response.data.message)
          setPassError([])
        }
      } else if (response.data === 'Password Set Successfully') {
        setPassSuccess('Password Set Successfully')
        setPassError([])
      } else if (
        response.data ===
        'password does not match, please ensure you have entered valid credentials.'
      ) {
        setPassError([
          'Existing password does not match, please ensure you have entered valid credentials.',
        ])
        setPassSuccess('')
      } else {
        setPassError(['An unexpected error has occurred.'])
        setPassSuccess('')
      }
    } catch (error) {
      if (error.response.status === 500) {
        setPassError([
          'Could not change password, please check the credentials you entered are valid',
        ])
        setPassSuccess('')
      }
    } finally {
      setLoadingState(false)
    }
  }

  // Run conditions and pw change request
  const handleSubmit = e => {
    e.preventDefault()
    sessionUsername()

    if (validPass()) {
      handlePassChange()
    }
  }

  const settingsTab = () => (
    <div className='settings-section'>
      {!isSSOUser && (
        <form onSubmit={handleSubmit} className='w-full max-w-lg mt-4s'>
          {passSuccess && (
            <Alert title='Success' type='success' margin='mb-6'>
              <p>{passSuccess}</p>
            </Alert>
          )}

          {passError.length > 0 && (
            <Alert title='Error' type='error' margin='mb-6'>
              <ul>
                {passError.map(msg => (
                  <li key={msg} className='ml-4 list-disc'>
                    {msg}
                  </li>
                ))}
              </ul>
            </Alert>
          )}

          <h4 className='mb-4'>Change your password</h4>
          {loadingState ? (
            <div id='loading-spinner'>
              <div className='loading' />
            </div>
          ) : (
            <>
              <div className='flex flex-col space-y-2 my-2'>
                <label htmlFor='existingpassword'>Existing password</label>
                <input
                  type='password'
                  className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400'
                  onChange={e => {
                    setOldPass(e.target.value)
                  }}
                />
              </div>
              <div className='flex flex-col space-y-2 my-2'>
                <label htmlFor='newpassword'>New password</label>
                <input
                  type='password'
                  className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400'
                  onChange={e => {
                    setNewPass(e.target.value)
                  }}
                />
              </div>
              <div className='flex flex-col space-y-2 my-2'>
                <label htmlFor='confirmnewpassword'>Confirm new password</label>
                <input
                  type='password'
                  className='flex rounded-md border p-2 border-gray-400 bg-white text-gray-700 my-[10px] focus:outline-blue-400'
                  onChange={e => {
                    setConfirmNewPass(e.target.value)
                  }}
                />
              </div>
            </>
          )}

          <button className='btn cyan-btn mt-4' type='submit'>
            Change Password
          </button>
        </form>
      )}

      {isSSOUser && (
        <Alert title='SSO User' type='info' margin='mt-4'>
          <p>
            You are authenticated via SSO and you cannot change your password
            from here.
          </p>
        </Alert>
      )}
    </div>
  )

  return (
    <div className='ui-panel my-account'>
      <div className='heading'>
        <h2>Settings</h2>
      </div>
      <div className='action-body no-progress-bar flex flex-col'>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label='Settings tabs'
        >
          <Tab label='Password' value={tabValues.user} />
          {isClientAdmin && (
            <Tab label='Single Sign-On' value={tabValues.sso} />
          )}
          {isClientAdmin && (
            <Tab label='User Management' value={tabValues.userManagement} />
          )}
          {enableDarkModeValue && (
            <Tab label='Appearance' value={tabValues.appearance} />
          )}
          {enableTenantTags && (
            <Tab label='Tenant Tags' value={tabValues.tenantTags} />
          )}
        </Tabs>

        <TabPanel value={selectedTab} selected={selectedTab === tabValues.user}>
          {settingsTab()}
        </TabPanel>

        {isClientAdmin && (
          <>
            <TabPanel
              value={selectedTab}
              selected={selectedTab === tabValues.sso}
            >
              {isClientAdmin && <SSOAdmin />}
            </TabPanel>
            <TabPanel
              value={selectedTab}
              selected={selectedTab === tabValues.userManagement}
            >
              {isClientAdmin && <SSOUsers />}
            </TabPanel>
          </>
        )}

        {enableDarkModeValue && (
          <TabPanel
            value={selectedTab}
            selected={selectedTab === tabValues.appearance}
          >
            <DarkModeControl />
          </TabPanel>
        )}

        {enableTenantTags && (
          <TabPanel
            value={selectedTab}
            selected={selectedTab === tabValues.tenantTags}
          >
            <TenantTagSettings />
          </TabPanel>
        )}
      </div>
    </div>
  )
}

export default Settings
