import { bool, func, node, string } from 'prop-types'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContentPanel = ({ isOpen, togglePanel, panelHeading, children }) => (
  <section
    aria-hidden={!isOpen}
    className={`shadow-lg fixed z-10 right-0 top-[64px] overflow-auto h-full bg-white w-[540px] transform transition-transform ${
      isOpen ? 'translate-x-0' : 'translate-x-full'
    }`}
  >
    <div className='flex justify-between items-center p-6'>
      <h3 className='text-3xl'>{panelHeading}</h3>
      <FontAwesomeIcon
        className='notification-list-close-btn cursor-pointer text-xl'
        icon={faClose}
        onClick={togglePanel}
      />
    </div>
    <hr />
    <div className='p-6 h-full'>{children}</div>
  </section>
)

export default ContentPanel

ContentPanel.propTypes = {
  isOpen: bool.isRequired,
  togglePanel: func.isRequired,
  panelHeading: string.isRequired,
  children: node.isRequired,
}
