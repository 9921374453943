import { string, number, oneOfType, arrayOf, shape } from 'prop-types'

const RestoreSummary = ({ formResponses }) => (
  <ul>
    {formResponses.map((response, index) => (
      <li key={index}>
        {response.step === 1 && (
          <div>
            <p>
              <strong>Tenant to restore </strong>
            </p>
            <ul>
              {response.response.map((tenants, index) => (
                <li key={index}>{tenants.tenantFriendlyName}</li>
              ))}
            </ul>
          </div>
        )}
        {response.step === 2 && (
          <div>
            <p>
              <strong>Selected Backup </strong>
            </p>
            <p>
              {Array.isArray(response.response) &&
              typeof response.response[0] === 'string'
                ? response.response.join(', ')
                : response.response.map(d => d.value).join(', ')}
            </p>
          </div>
        )}
        {response.step === 3 && (
          <div>
            <p>
              <strong>Policies to restore - {response.response.length} </strong>
            </p>
            <ul className='confirm-policies'>
              {response.response.map((policy, index) => (
                <li key={index} className='sm-text'>
                  {policy.displayName || policy.name || policy.PolicyName}
                </li>
              ))}
            </ul>
          </div>
        )}
      </li>
    ))}
  </ul>
)

export default RestoreSummary

RestoreSummary.propTypes = {
  formResponses: arrayOf(
    shape({
      step: number.isRequired,
      response: oneOfType([
        arrayOf(
          shape({
            tenantFriendlyName: string,
          })
        ),
        arrayOf(
          shape({
            value: string,
          })
        ),
        arrayOf(
          shape({
            displayName: string,
            name: string,
            PolicyName: string,
          })
        ),
        arrayOf(string),
        shape({}),
        string,
      ]),
    })
  ).isRequired,
}
