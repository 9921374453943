import { Controller, useForm } from 'react-hook-form'
import { HexColorPicker } from 'react-colorful'
import { func, string } from 'prop-types'
import useCreateOrUpdateTenantTag from './api/useCreateOrUpdateTenantTag'
import Alert from '../Alert/Alert'
import FormControlButtons from '../Form/components/FormControlButtons/FormControlButtons'
import './TenantTags.css'
import Tag from '../Tag/Tag'
import { hexToRgb } from '../../utils/colours'
import FormTextInput from '../Form/components/FormTextInput/FormTextInput'
import FormTextArea from '../Form/components/FormTextArea/FormTextArea'

const TenantTagForm = ({
  closePanel,
  tagId,
  initialTagName,
  initialDescription,
  initialSelectedColour,
  onSuccessfulAction,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch,
    control,
  } = useForm({
    mode: 'onBlur',
    values: {
      tag: initialTagName || '',
      description: initialDescription || '',
      colour: initialSelectedColour || '#E7F3FB',
    },
  })
  const tag = watch('tag')
  const colour = watch('colour')
  const description = watch('description')

  const createOrUpdateTenantTag = useCreateOrUpdateTenantTag(() => {
    reset()
    onSuccessfulAction()
    closePanel()
  }, !!initialTagName)

  const onSubmit = data => {
    // only pass tagId if it's an update operation
    const newTag = { ...data, colour, ...(tagId && { id: tagId }) }
    createOrUpdateTenantTag.mutate(newTag)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label='tag-form'>
      <div className='flex flex-col gap-4 content-between pb-6'>
        <Alert type='info-cyan'>
          <strong>Tip</strong>: Press{' '}
          <code className='bg-gray-300 px-1'>Win + .</code> to add an emoji to
          your tag name
        </Alert>
        <FormTextInput
          label='Tag Name'
          register={() =>
            register('tag', {
              required: 'Tag name required',
              maxLength: { value: 32, message: 'Max length 32 characters' },
            })
          }
          errors={errors.tag}
        />
        <FormTextArea
          register={() =>
            register('description', {
              required: false,
              maxLength: { value: 200, message: 'Max length 200 characters' },
            })
          }
          errors={errors.description}
          label='Description'
        />
        <div className='py-2'>
          <Tag
            text={tag || 'Tag Preview'}
            description={description}
            colourRGB={hexToRgb(colour)}
          />
        </div>
        <div className='flex flex-col'>
          <label htmlFor='color'>Select A Colour Scheme</label>
          <div className='mt-2 create-tag-picker'>
            <Controller
              name='colour'
              control={control}
              render={({ field: { onChange } }) => (
                <HexColorPicker
                  color={colour}
                  onChange={onChange}
                  className='w-full'
                />
              )}
            />
          </div>
        </div>
        {createOrUpdateTenantTag.isError && (
          <Alert
            type='error'
            title={createOrUpdateTenantTag.error.response.data.message}
            margin='mt-3'
          >
            <ul>
              {createOrUpdateTenantTag.error.response.data.errors?.map(
                error => (
                  <li>{error}</li>
                )
              )}
            </ul>
          </Alert>
        )}
      </div>

      <FormControlButtons
        onCancel={() => {
          reset()
          closePanel()
          onSuccessfulAction()
        }}
        onReset={() => reset()}
        submitText={initialTagName ? 'Update' : 'Create'}
        resetDisabled={!isDirty || createOrUpdateTenantTag.isPending}
        confirmDisabled={
          !isDirty ||
          Object.values(errors).length !== 0 ||
          createOrUpdateTenantTag.isPending
        }
      />
    </form>
  )
}

TenantTagForm.defaultProps = {
  tagId: '',
  initialTagName: '',
  initialDescription: '',
  initialSelectedColour: '#E7F3FB',
}

TenantTagForm.propTypes = {
  tagId: string,
  initialTagName: string,
  initialDescription: string,
  initialSelectedColour: string,
  closePanel: func.isRequired,
  onSuccessfulAction: func.isRequired,
}

export default TenantTagForm
