import { getFlagsForPolicy } from '../AdditionalOptions/AdditionalOptionsConfig'

const createDeployPolicyPayload = (
  policiesToDeploy,
  additionalSettings,
  sourceTenant,
  destinationTenants
) => {
  const payload = []

  policiesToDeploy.forEach(policy => {
    const { policyGuid, policyTypeId, policyName, priority, subjectPolicyId } =
      policy
    const additionalSettingsForPolicy = getFlagsForPolicy(
      additionalSettings,
      policyTypeId
    )

    if (
      additionalSettingsForPolicy.deployAllUsers === true ||
      additionalSettingsForPolicy.deployAllDevices === true ||
      additionalSettingsForPolicy.deployAllUsersAndDevices === true
    ) {
      additionalSettingsForPolicy.deployAssignments = false
    }

    destinationTenants.forEach(destinationTenant => {
      const currentPolicy = {
        clientTenantIdDeployFrom: sourceTenant.clientTenantId,
        clientTenantIdDeployTo: +destinationTenant.clientTenantId,
        policyGuid,
        policyTypeId,
        priority,
        displayName: policyName,
        saveOverride: true,
        overridePolicyId: subjectPolicyId,
        ...additionalSettingsForPolicy,
      }

      payload.push(currentPolicy)
    })
  })

  return payload
}

export default createDeployPolicyPayload
