import { shape, string } from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons'

import useDeleteTenant from '../../../api/useDeleteTenant'

import Modal from '../../Modal/Modal'
import ModalActionButtons from '../../Modal/ModalActionButtons'
import Alert from '../../Alert/Alert'

const TenantManagementActionsCell = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false)

  const navigate = useNavigate()
  const deleteTenant = useDeleteTenant()

  const tenant = row.original

  return (
    <div className='flex space-x-2'>
      <button
        type='button'
        onClick={() => {
          navigate(`/tenant-refresh?clientTenantId=${tenant.clientTenantId}`)
        }}
        className='navy-btn hover:navy-btn text-white p-2 rounded-md flex justify-center items-center w-8 h-8'
      >
        <FontAwesomeIcon icon={faRefresh} />
      </button>
      <button
        className='navy-btn text-white p-2 rounded-md flex justify-center items-center w-8 h-8'
        type='button'
        onClick={() => {
          navigate(`/edit-tenant/${tenant.clientTenantId}`)
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <button
        type='button'
        className='navy-btn text-white p-2 rounded-md flex justify-center items-center w-8 h-8'
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
      <Modal isOpen={isOpen} setModalOpen={setIsOpen}>
        <h2 className='text-xl font-bold mb-4'>
          Are you sure you want to delete "{tenant.tenantFriendlyName}" tenant?
        </h2>
        <Alert type='warning'>
          This will remove the tenant from the Inforcer platform, however the
          Application Registration will need to be removed manually.
        </Alert>

        <ModalActionButtons
          onCancel={() => {
            setIsOpen(false)
          }}
          onConfirm={() => deleteTenant.mutate(tenant.clientTenantId)}
          cancelActionText='Cancel'
          confirmActionText='Confirm'
        />
      </Modal>
    </div>
  )
}

TenantManagementActionsCell.propTypes = {
  row: shape({
    original: shape({
      clientTenantId: string.isRequired,
      tenantFriendlyName: string.isRequired,
    }),
  }).isRequired,
}

export default TenantManagementActionsCell
