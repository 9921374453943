import { createContext, useReducer, useContext, useMemo } from 'react'
import { node } from 'prop-types'

const SelectionContext = createContext()

const selectionInitialState = {
  selectedPolicies: [],
  allSelected: false,
}

const selectionReducer = (state, action) => {
  switch (action.type) {
    case 'SELECT_ITEM':
      return {
        ...state,
        selectedPolicies: [...state.selectedPolicies, action.payload],
      }
    case 'DESELECT_ITEM':
      return {
        ...state,
        selectedPolicies: state.selectedPolicies.filter(
          policy => policy.id !== action.payload.id
        ),
      }
    case 'SELECT_GROUP':
      return {
        ...state,
        allSelected: true,
        selectedPolicies: [...state.selectedPolicies, ...action.payload],
      }
    case 'DESELECT_GROUP':
      return {
        ...state,
        selectedPolicies: state.selectedPolicies.filter(
          id => !action.payload.includes(id)
        ),
      }
    case 'SELECT_ALL':
      return {
        ...state,
        allSelected: action.payload,
        selectedPolicies: action.payload ? action.items : [],
      }
    case 'RESET':
      return selectionInitialState
    default:
      return state
  }
}

export const SelectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(selectionReducer, selectionInitialState)

  const contextValue = useMemo(() => {
    const getSelectedItemsCount = () => state.selectedPolicies.length

    const getGroupSelectedItemsCount = groupPolices =>
      groupPolices.filter(policy => state.selectedPolicies.includes(policy))
        .length

    const selectAll = (items, select) => {
      dispatch({
        type: 'SELECT_ALL',
        payload: select,
        items,
      })
    }

    const selectItem = (policy, select) => {
      dispatch({
        type: select ? 'SELECT_ITEM' : 'DESELECT_ITEM',
        payload: policy,
      })
    }

    const selectGroup = (groupPolicies, select) => {
      dispatch({
        type: select ? 'SELECT_GROUP' : 'DESELECT_GROUP',
        payload: groupPolicies,
      })
    }

    const resetSelectionState = () => {
      dispatch({
        type: 'RESET',
      })
    }

    return {
      state,
      dispatch,
      getSelectedItemsCount,
      getGroupSelectedItemsCount,
      selectAll,
      selectGroup,
      selectItem,
      resetSelectionState,
    }
  }, [state, dispatch])

  return (
    <SelectionContext.Provider value={contextValue}>
      {children}
    </SelectionContext.Provider>
  )
}

export const useSelection = () => {
  const context = useContext(SelectionContext)
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider')
  }
  return context
}

SelectionProvider.propTypes = {
  children: node.isRequired,
}
