import {
  arrayOf,
  bool,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { MaterialReactTable } from 'material-react-table'
import { defaultCellSx, defaultTableProperties } from '../config'
import './ListTable.css'

const ListTable = ({
  columns,
  data,
  setSelectedListItems,
  selectedListItems,
  enableMultiRowSelection,
  uniqueKey,
  loading,
  enableRowSelection,
  positionGlobalFilter,
  enableGlobalFilter,
  renderTopToolbar,
  columnOrder,
}) => {
  const initialSelection = selectedListItems.reduce(
    (acc, selectedItem) => ({ ...acc, [selectedItem[uniqueKey]]: true }),
    {}
  )
  const [rowSelectionState, setRowSelectionState] = useState(initialSelection)

  // to abstract away the implementation details of the table row selection management we
  // allow the user to pass in an array of entries from the data and we convert that into an
  // array that the table can use to handle the management state
  useEffect(() => {
    if (rowSelectionState && setSelectedListItems) {
      const filteredData = data?.filter(
        entry => !!rowSelectionState[entry[uniqueKey]]
      )
      setSelectedListItems(filteredData)
    }
  }, [data, rowSelectionState, setSelectedListItems, uniqueKey])

  // this is where we take the selected list items (e.g. the data entries) and convert
  // them into the object entries to set the respective rows in the table
  const selectedRows = useMemo(
    () =>
      selectedListItems.reduce(
        (acc, selectedItem) => ({ ...acc, [selectedItem[uniqueKey]]: true }),
        {}
      ),
    [selectedListItems, uniqueKey]
  )

  const tableColumnDefinitions = useMemo(
    () =>
      columns.map(
        ({
          header,
          accessor,
          cell,
          sx,
          muiTableHeadCellProps,
          muiTableBodyCellProps,
          ...rest
        }) => ({
          header,
          accessorKey: accessor,
          muiTableBodyCellProps: {
            sx: {
              ...defaultCellSx,
              ...sx,
            },
            ...muiTableBodyCellProps,
          },
          muiTableHeadCellProps: {
            sx: {
              ...defaultCellSx,
              ...sx,
            },
            ...muiTableHeadCellProps,
          },
          size: 100,
          Cell: cell,
          ...rest,
        })
      ),
    [columns]
  )

  return (
    <MaterialReactTable
      columns={tableColumnDefinitions}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...defaultTableProperties}
      renderTopToolbar={
        renderTopToolbar ?? defaultTableProperties.renderTopToolbar
      }
      positionGlobalFilter={positionGlobalFilter}
      getRowId={row => row[uniqueKey]}
      data={data}
      enableRowSelection={enableRowSelection}
      enableMultiRowSelection={enableMultiRowSelection}
      enableGlobalFilter={enableGlobalFilter}
      onRowSelectionChange={setRowSelectionState}
      state={{ rowSelection: selectedRows, isLoading: loading }}
      initialState={{ ...defaultTableProperties.initialState, columnOrder }}
    />
  )
}

ListTable.defaultProps = {
  loading: false,
  enableRowSelection: false,
  enableMultiRowSelection: false,
  selectedListItems: [],
  setSelectedListItems: undefined,
  renderTopToolbar: defaultTableProperties.renderTopToolbar,
  positionGlobalFilter: defaultTableProperties.positionGlobalFilter,
  enableGlobalFilter: defaultTableProperties.enableGlobalFilter,
  enableFacetedValues: defaultTableProperties.enableFacetedValues,
  columnOrder: undefined,
}

ListTable.propTypes = {
  columns: arrayOf(
    shape({
      header: string.isRequired,
      accessor: string,
      cell: func,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: arrayOf(object).isRequired,
  uniqueKey: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedListItems: arrayOf(object),
  setSelectedListItems: func,
  enableMultiRowSelection: bool,
  loading: bool,
  renderTopToolbar: func,
  enableRowSelection: oneOfType([bool, func]),
  positionGlobalFilter: string,
  enableGlobalFilter: bool,
  enableFacetedValues: bool,
  columnOrder: arrayOf(string),
}

export default ListTable
