import Axios from 'axios'
import { useEffect } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const useTenantCompare = () => {
  const queryClient = useQueryClient()

  const getTenantComparison = useMutation({
    gcTime: 0,
    mutationFn: async ({
      baselineGroupId,
      baselineClientTenantId,
      subjectClientTenantId,
    }) => {
      const response = await Axios.post(
        `${process.env.REACT_APP_MIDDLEWARE_URL}/compareTenantPolicies`,
        {
          baselineGroupId,
          baselineClientTenantId,
          subjectClientTenantIds: [subjectClientTenantId],
          useBaselineLiveData: false,
          getSimilarPolicies: true,
          getStringifiedDiff: true,
        }
      )
      return response.data
    },
    enabled: false,
  })

  useEffect(() => {
    queryClient.invalidateQueries('tenant-summary')
  }, [getTenantComparison.data, queryClient])

  return {
    getTenantComparison,
  }
}

export default useTenantCompare
